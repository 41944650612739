<style lang="scss">
#client-service {
  input[type="file"] {
    display: none;
  }
}
.custom-file-upload {
  padding: 6px 12px;
  cursor: pointer;
  background: #1bc5bd;
  color: white;
  border-radius: 3px;
}
.input-group-text {
  height: 66%;
}
// .row {
//   .input-group-text {
//     height: 100%;
//   }
// }
</style>
<style scoped>
@media (max-width: 1351px) and (min-width: 765px) {
  .respo-row .col-md-3 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .respo-row .col-md-2 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
</style>
<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card form-body">
        <div class="card-body">
          <form action="">
            <div class="d-flex">
              <legend><h6>Service Detail</h6></legend>
              <div class="form-group mr-5" style="width: 20%;">
                <label for="">Show capture option</label>
                <span class="switch">
                  <input
                    type="checkbox"
                    v-model="show_capture_method"
                    class="switch"
                    id="show_capture_method"
                  />
                  <label for="show_capture_method"></label>
                </span>
              </div>

              <div class="form-group mr-5" style="width: 20%;">
                <label for="">Email verification</label>
                <span class="switch">
                  <input
                    type="checkbox"
                    v-model="email_verification"
                    class="switch"
                    id="email_verification"
                  />
                  <label for="email_verification"></label>
                </span>
              </div>
              <div class="form-group mr-5" style="width: 20%;">
                <label for="">SMS verification</label>
                <span class="switch">
                  <input
                    type="checkbox"
                    v-model="sms_verification"
                    class="switch"
                    id="sms_verification"
                  />
                  <label for="sms_verification"></label>
                </span>
              </div>
              <div class="form-group mr-5" style="width: 20%;">
                <label for="">Enable ORI Rate</label>
                <span class="switch">
                  <input
                    type="checkbox"
                    v-model="enable_ori_rate"
                    class="switch"
                    id="enable_ori_rate"
                  />
                  <label for="enable_ori_rate"></label>
                </span>
              </div>

              <div class="form-group mr-5" style="width: 20%;">
                <label for="">Service By Mail</label>
                <span class="switch">
                  <input
                    type="checkbox"
                    v-model="is_service_by_mail"
                    class="switch"
                    id="is_service_by_mail"
                  />
                  <label for="is_service_by_mail"></label>
                </span>
              </div>
              <div class="form-group mr-5" style="width: 20%;">
                <label for="">Featured</label>
                <span class="switch">
                  <input
                    type="checkbox"
                    v-model="is_featured"
                    class="switch"
                    id="is_featured"
                  />
                  <label for="is_featured"></label>
                </span>
              </div>
              <div class="form-group mr-5" style="width: 20%;">
                <label for="">Two Factor</label>
                <span class="switch">
                  <input
                    type="checkbox"
                    v-model="enable_two_factor"
                    class="switch"
                    id="enable_two_factor"
                  />
                  <label for="enable_two_factor"></label>
                </span>
              </div>
              <div class="form-group" style="width: 20%;">
                <label for="">Active</label>
                <span class="switch">
                  <input
                    type="checkbox"
                    v-model="is_active"
                    class="switch"
                    id="switch-normal"
                  />
                  <label for="switch-normal"></label>
                </span>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="service">Service Type</label>
                  <select
                    v-model="service_code"
                    name=""
                    class="form-control"
                    id=""
                  >
                    <option value="">Please select a service type</option>
                    <option
                      v-for="(citem, index) in getServiceCodes"
                      :key="index"
                      :value="citem.id"
                    >
                      {{ citem.service_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">Destination agency identifier(DAI) </label>
                  <input
                    type="text"
                    v-model="dai"
                    name=""
                    class="form-control"
                    id=""
                    :class="errors['dai'] ? 'border border-danger' : ''"
                  />
                  <span v-if="errors['dai']" class="text-danger">{{
                    errors["dai"][0]
                  }}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="">Name <span class="text-danger">*</span></label>
              <input
                type="text"
                v-model="service_name"
                name=""
                class="form-control"
                id=""
                :class="errors['name'] ? 'border border-danger' : ''"
              />
              <span v-if="errors['name']" class="text-danger">{{
                errors["name"][0]
              }}</span>
            </div>

            <div class="form-group">
              <label for=""
                >Description <span class="text-danger">*</span></label
              >
              <textarea
                v-model="service_description"
                name=""
                id=""
                cols="30"
                rows="2"
                class="form-control"
                :class="errors['description'] ? 'border border-danger' : ''"
              ></textarea>
              <span v-if="errors['description']" class="text-danger">{{
                errors["description"][0]
              }}</span>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">Service Type </label>
                  <select
                    v-model="service_type"
                    name=""
                    id=""
                    class="form-control"
                  >
                    <option value="none">None</option>
                    <option value="citizen">Enable Citizen</option>
                    <option value="DISCLOSURE">Disclosure</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6" v-if="!isApostille">
                <div class="form-check">
                  <!-- <input
                    type="checkbox"
                    class="form-check-input"
                    id="exampleCheck1"
                    v-model="is_drug_testing"
                  /> -->
                  <label class="form-check-label" for="exampleCheck1"
                    >Is Drug Testing</label
                  >
                  <span class="switch mt-5">
                    <input
                      type="checkbox"
                      v-model="is_drug_testing"
                      class="switch"
                      id="switch-normal-drug"
                    />
                    <label for="switch-normal-drug"></label>
                  </span>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for=""
                >Rate Title <span class="text-danger">*</span></label
              >
              <input
                type="text"
                v-model="rate_title"
                name=""
                class="form-control"
                id=""
                :class="errors['rate_title'] ? 'border border-danger' : ''"
              />
              <span v-if="errors['rate_title']" class="text-danger">{{
                errors["rate_title"][0]
              }}</span>
            </div>
            <legend><h6>{{isApostille ? 'Normal Service Charge Detail' : 'Service Charge Detail' }}</h6></legend>
            <hr />
            <div class="row respo-row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for=""
                    >Default Rate <span class="text-danger">*</span></label
                  >
                  <input
                    type="number"
                    v-model="default_rate"
                    name=""
                    class="form-control"
                    id=""
                    min="0"
                    :class="
                      errors['default_rate'] ? 'border border-danger' : ''
                    "
                  />
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for=""
                    >Default Qty <span class="text-danger">*</span></label
                  >
                  <input
                    type="number"
                    min="0"
                    v-model="default_qty"
                    name=""
                    class="form-control"
                    id=""
                    :class="errors['default_qty'] ? 'border border-danger' : ''"
                  />
                </div>
              </div>

              <div class="col-md-3" v-if="!isApostille">
                <div class="form-group">
                  <label for="">Additional Rate</label>
                  <!-- <input
                    type="number"
                    v-model="additional_rate"
                    name=""
                    class="form-control"
                    id=""
                  /> -->

                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <input
                          type="checkbox"
                          v-model="has_additional_rate"
                          aria-label="Checkbox for following text input"
                        />
                      </div>
                    </div>
                    <input
                      type="text"
                      :disabled="!has_additional_rate"
                      v-model="additional_rate"
                      class="form-control"
                      aria-label="Text input with checkbox"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Tax(%)</label>
                  <input
                    type="number"
                    v-model="tax_percentag"
                    name=""
                    class="form-control"
                    id=""
                    min="0"
                  />
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Commission($)</label>
                  <input
                    type="number"
                    v-model="commission_percentage"
                    name=""
                    class="form-control"
                    id=""
                    min="0"
                  />
                </div>
              </div>
            </div>
            <div v-if="isApostille">
              <legend>
                <h6>Expedited Charge Details</h6>
              </legend>
              <hr>
              <div class="row respo-row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Expedited Rate <span class="text-danger">*</span></label>
                    <input
                      type="number"
                      v-model="default_expedited_rate"
                      name=""
                      class="form-control"
                      id=""
                      min="0"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isApostille">
              <legend>
                <h6>Apostille Rate Notes</h6>
              </legend>
              <hr>
              <div class="row respo-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Regular Rate Note<span class="text-danger">*</span></label>
                    <textarea type="text" v-model="default_rate_note" class="form-control" rows="3" style="max-height: 200px; min-height: 100px;" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Expedited Rate Note<span class="text-danger">*</span></label>
                    <textarea type="text" v-model="expedited_rate_note" class="form-control" rows="3" style="max-height: 200px; min-height: 100px;" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Document Translation Rate Note<span class="text-danger">*</span></label>
                    <textarea type="text" v-model="doc_translation_rate_note" class="form-control" rows="3" style="max-height: 200px; min-height: 100px;" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Document Scanning Rate Note<span class="text-danger">*</span></label>
                    <textarea type="text" v-model="doc_scan_rate_note" class="form-control" rows="3" style="max-height: 200px; min-height: 100px;" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isApostille">
              <legend>
                <h6>Shipping Service Rate</h6>
              </legend>
              <hr>
              <div class="row respo-row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="">Shipping Company Name<span class="text-danger">*</span></label>
                    <input type="text" v-model="shipping_details.shipping_company_name" class="form-control" :class="{'border border-danger': errors['mailing_company_name']}" />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Domestic Shipping Rate <span class="text-danger">*</span></label>
                    <input type="number" v-model="shipping_details.domestic_shipping_cost" class="form-control" :class="{'border border-danger': errors['domestic_shipping_rate']}" />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">International Shipping Rate <span class="text-danger">*</span></label>
                    <input type="number" v-model="shipping_details.international_shipping_cost" class="form-control" :class="{'border border-danger': errors['international_shipping_rate']}" />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Eastern Europe Shipping Rate <span class="text-danger">*</span></label>
                    <input type="number" v-model="shipping_details.eastern_europe_shipping_cost" class="form-control" :class="{'border border-danger': errors['international_shipping_rate']}" />
                  </div>
                </div>
              </div>


              <legend>
                <h6>Optional Service Addons Rate</h6>
              </legend>
              <hr>
              <div class="row respo-row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="">Document Translation rate (Per page) <span class="text-danger">*</span></label>
                    <input type="number" v-model="doc_translation_rate" name="" class="form-control" id="" min="0" :class="errors['doc_translation_rate'] ? 'border border-danger' : ''
                      " />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="">Scan of Completed Docs Rate (Per doc)<span class="text-danger">*</span></label>
                    <input type="number" v-model="doc_scan_rate" name="" class="form-control" id="" min="0" :class="errors['doc_scan_rate'] ? 'border border-danger' : ''
                      " />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="form-group">
              <label for="">Book Now Note</label>
              <editor
                api-key="71vg7medn8k1guoqbic2prdchbq0em4dqvhruo5ko352ck6h"
                v-model="book_now_note"
                :init="config"
                v-if="showBookNow"
              />
            </div>
            <div
              class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4"
              v-if="image_place != ''"
            >
              <div
                class="symbol-label"
                :style="
                  'background-size: contain;background-image: url(' +
                image_place +
                ')'
                "
              ></div>
            </div>
            <br />
            <div class="form-group col-md-6">
              <div class="journey" v-if="showImage">
                <canvas
                  id="imageCanvas"
                  ref="imageCanvas"
                  style="width: 120px"
                ></canvas>
                <!-- <image-crop
                  :image_base64="image_base64"
                  :aspectRatioheight="getAspectheight"
                  :aspectRatiowidth="getAspectwidth"
                  :canvasheight="getCanvasheight"
                  :canvaswidth="getCanvasheight"
                  @base64Img="changeImage"
                ></image-crop> -->
              </div>
            </div>

            <label class="custom-file-upload">
              <input
                type="file"
                id="imageLoader"
                @change="updateCanvasImage"
                hidden
              />
              <i class="fa fa-upload mr-2" style="color: white"></i> Upload
              Image
            </label>
            <br />
            <span v-if="errors['image']" class="text-danger">{{
              errors["image"][0]
            }}</span>

            <!-- <div class="form-group">
              <label for=""></label>
            </div> -->
          </form>
        </div>
        <div class="card-footer">
          <button
            v-if="isEdit"
            class="btn btn-success"
            @click="update('kt_update_service')"
            style="float: right"
            ref="kt_update_service"
          >
            <i class="fa fa-check"></i>Update
          </button>
          <button
            v-if="!isEdit"
            class="btn btn-success ml-3"
            style="float: right"
            @click="store('kt_save_service', 'S')"
            ref="kt_save_service"
          >
            <i class="fa fa-check"></i>Save
          </button>
          <button
            v-if="!isEdit"
            class="btn btn-success"
            style="float: right"
            @click="store('kt_save_and_add_service', 'SAA')"
            ref="kt_save_and_add_service"
          >
            <i class="fa fa-check"></i>Save and Add Another
          </button>
          <button
            ref="kt_save_add_another_company"
            class="btn btn-danger mr-3"
            @click="goBack()"
            style="float: right"
          >
            <i class="fa fa-arrow-left"></i> Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Editor from "@tinymce/tinymce-vue";
// import ImageCrop from "@/components/ImageCrop";
import { mapGetters } from "vuex";
import {
  UPDATE_B_SERVICE,
  STORE_B_SERVICE,
  GET_B_SERVICE_DATA,
  FETCH_SERVICE_CODES
} from "@/core/services/store/actions.type";
export default {
  components: {
    editor: Editor
  },
  data() {
    return {
      isEdit: false,
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Services",
          route: "client.service"
        },
        {
          id: 3,
          title: "Create New Service",
          route: ""
        }
      ],
      editor: ClassicEditor,
      config: {
        height: 500,
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount"
        ],
        toolbar:
          "undo redo | formatselect | fontsizeselect |bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | code | help",
        fontsize_formats:
          "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt"
      },
      showImage: false,
      book_now_note: "",
      service_code: "",
      //form
      rate_title: "",
      service_name: "",
      dai: "",
      service_description: "",
      service_type: "none",
      image: "",
      is_active: true,
      default_rate: 0,
      default_qty: 1,
      enable_two_factor: false,
      additional_rate: 0,
      commission_percentage: 0,
      tax_percentag: 0,
      errors: [],
      show_capture_method: false,
      email_verification: false,
      sms_verification: false,
      is_featured: false,
      e: "",
      image_place: "",
      has_additional_rate: false,
      image_base64: "",
      image_crop_base64: "",
      is_drug_testing: 0,
      is_service_by_mail: false,
      enable_ori_rate: false,
      isApostille: false,
      doc_translation_rate: 0,
      doc_scan_rate: 0,
      shipping_details:{
        shipping_company_name: "",
        domestic_shipping_cost: 0,
        international_shipping_cost: 0,
        eastern_europe_shipping_cost: 0,
      },
      default_expedited_rate: 0,
      expedited_rate_note: '',
      default_rate_note: '',
      doc_translation_rate_note: '',
      doc_scan_rate_note: '',
      showBookNow: false,
    };
  },
  computed: {
    ...mapGetters([
      "getAspectheight",
      "getAspectwidth",
      "getCanvasheight",
      "getCanvasheight",
      "getServiceCodes"
    ])
  },
  watch: {
    service_code: function () {
      this.checkApostille();
    },
    getServiceCodes: function () {
      this.checkApostille();
    }
  },
  mounted() {
    this.$store.dispatch(FETCH_SERVICE_CODES);

    setTimeout(() => {
      this.showBookNow = true;
    }, 500);
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.isEdit = this.$route.name == "client.service.edit" ? true : false;
    var id = this.$route.params.id;
    if (this.isEdit) {
      this.breadCrumbs[2].title = "";
      this.$store
        .dispatch(GET_B_SERVICE_DATA, id)
        .then(data => {
          this.service_name = data.name;
          this.breadCrumbs[2].title = "Edit | " + data.name;
          this.service_description = data.description;
          this.service_type = data.service_type;
          this.is_active = data.is_active;
          this.service_code = data.service_code;
          this.image_place = data.image;
          this.default_rate = data.default_rate;
          this.additional_rate = data.additional_rate;
          this.tax_percentag = data.tax_percentage;
          this.commission_percentage = data.commission_percentage;
          this.has_additional_rate = data.has_additional_rate;
          this.enable_two_factor = data.enable_two_factor;
          this.default_qty = data.default_qty;
          this.sms_verification = data.sms_verification;
          this.email_verification = data.email_verification;
          this.rate_title = data.rate_title;
          this.book_now_note = data.book_now_note;
          this.show_capture_method = data.show_capture_method;
          this.enable_ori_rate = data.enable_ori_rate;
          this.dai = data.dai;
          this.is_drug_testing =
            data.is_drug_testing == null ? 0 : data.is_drug_testing;
          this.is_featured = data.is_featured == null ? 0 : data.is_featured;
          this.is_service_by_mail = data.is_service_by_mail;
          this.doc_translation_rate = data.doc_translation_rate;
          this.doc_scan_rate = data.doc_translation_rate;
          this.shipping_details = data.shipping_details;
          this.default_expedited_rate = data.default_expedited_rate;
          this.expedited_rate_note = data.expedited_rate_note ;
          this.default_rate_note = data.default_rate_note ;
          this.doc_translation_rate_note = data.doc_translation_rate_note ;
          this.doc_scan_rate_note = data.doc_scan_rate_note ;

          // this.company_name = data.company_name;
          // this.abbreviation_code = data.abbreviation_code;
          // this.address = data.address;
          // this.contact_name = data.contact_name;
          // this.contact_email = data.contact_email;
          // this.contact_phone_no = data.contact_phone_number;
          // this.is_active = data.is_active;
        })
        .catch(() => {
          this.$toastr.e("Service detail not found !");

          this.$router.push({ name: "client.service" });
        });
    }
  },
  methods: {
    checkApostille(){
      var val = this.getServiceCodes.find(obj => obj.id == this.service_code);
      if(val){
        if (val.code == "apostille") {
          this.isApostille = true;
        }else{
          this.isApostille = false;
        }
      }
    },
    goBack() {
      var client_slug = this.$route.params.client_slug;
      this.$router.push({
        name: "client.service",
        params: { client_slug: client_slug }
      });
    },
    clearForm() {
      this.service_name = "";
      this.service_code = "";
      this.service_description = "";
      this.service_type = "";
      this.image_base64 = "";
      this.is_active = true;
      this.default_rate = 0;
      this.additional_rate = 0;
      this.commission_percentage = 0;
      this.tax_percentag = 0;
      this.image_place = "";
      this.default_qty = 1;
      this.rate_title = "";
      this.enable_ori_rate = false;
      this.show_capture_method = false;
      this.sms_verification = false;
      this.email_verification = false;
      this.enable_two_factor = false;
      this.dai = "";
      this.is_drug_testing = false;
      this.is_featured = false;
      this.is_service_by_mail = false;
      this.doc_translation_rate = 0;
      this.doc_scan_rate = 0;
      this.shipping_details = {
          shipping_company_name: "",
          domestic_shipping_cost: 0,
          international_shipping_cost: 0,
          eastern_europe_shipping_cost: 0,
        };
      this.default_expedited_rate = 0;
      this.expedited_rate_note = '' ;
      this.default_rate_note = '' ;
      this.doc_translation_rate_note = '' ;
      this.doc_scan_rate_note = '' ;
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    changeImage(val) {
      this.image_crop_base64 = val;
    },
    update(ref) {
      this.loadingButton(ref);
      // let img;
      // if (this.showImage) {
      //   img = this.image_crop_base64;
      // } else {
      //   img = "none";
      // }

      var _data = {
        name: this.service_name,
        description: this.service_description,
        service_type: this.service_type,
        image: this.image != "" ? this.image : "none",
        is_active: this.is_active,
        service_code: this.service_code,
        default_rate: this.default_rate,
        additional_rate: this.additional_rate,
        enable_two_factor: this.enable_two_factor,
        commission_percentage: this.commission_percentage,
        tax_percentage: this.tax_percentag,
        has_additional_rate: this.has_additional_rate,
        default_qty: this.default_qty,
        rate_title: this.rate_title,
        book_now_note: this.book_now_note,
        show_capture_method: this.show_capture_method,
        is_drug_testing: this.is_drug_testing,
        enable_ori_rate: this.enable_ori_rate,
        sms_verification: this.sms_verification,
        email_verification: this.email_verification,
        is_featured: this.is_featured,
        dai: this.dai,
        is_service_by_mail: this.is_service_by_mail,
        doc_translation_rate: this.doc_translation_rate,
        doc_scan_rate: this.doc_scan_rate,
        shipping_details : this.shipping_details,
        default_expedited_rate: this.default_expedited_rate,
        expedited_rate_note : this.expedited_rate_note,
        default_rate_note : this.default_rate_note,
        doc_translation_rate_note : this.doc_translation_rate_note,
        doc_scan_rate_note : this.doc_scan_rate_note,
      };

      this.$store
        .dispatch(UPDATE_B_SERVICE, {
          id: this.$route.params.id,
          data: _data
        })
        .then(data => {
          this.$toastr.s(data.msg);
          this.closeBtnLoad(ref);
          this.$router.push({ name: "client.service" });
        })
        .catch(err => {
          this.errors = err;
          this.closeBtnLoad(ref);
        });
    },

    store(ref, saveType) {
      this.errors = [];
      this.loadingButton(ref);

      var _data = {
        name: this.service_name,
        description: this.service_description,
        service_type: this.service_type,
        image: this.image,
        is_active: this.is_active,
        service_code: this.service_code,
        default_rate: this.default_rate,
        additional_rate: this.additional_rate,
        commission_percentage: this.commission_percentage,
        tax_percentage: this.tax_percentag,
        enable_two_factor: this.enable_two_factor,
        has_additional_rate: this.has_additional_rate,
        default_qty: this.default_qty,
        enable_ori_rate: this.enable_ori_rate,
        rate_title: this.rate_title,
        show_capture_method: this.show_capture_method,
        book_now_note: this.book_now_note,
        is_drug_testing: this.is_drug_testing,
        email_verification: this.email_verification,
        sms_verification: this.sms_verification,
        is_featured: this.is_featured,
        dai: this.dai,
        is_service_by_mail: this.is_service_by_mail,
        doc_translation_rate: this.doc_translation_rate,
        doc_scan_rate: this.doc_scan_rate,
        shipping_details : this.shipping_details,
        default_expedited_rate : this.default_expedited_rate,
        expedited_rate_note : this.expedited_rate_note,
        default_rate_note : this.default_rate_note,
        doc_translation_rate_note : this.doc_translation_rate_note,
        doc_scan_rate_note : this.doc_scan_rate_note,
      };

      this.$store
        .dispatch(STORE_B_SERVICE, _data)
        .then(data => {
          this.$toastr.s(data.msg);
          this.closeBtnLoad(ref);

          //saveType = S =  Save Only and go back to list where SAA =  save and add another or stay in same page
          if (saveType == "S") {
            this.$router.push({ name: "client.service" });
          } else if (saveType == "SAA") {
            this.clearForm();
          }
        })
        .catch(err => {
          this.errors = err;
          this.closeBtnLoad(ref);
        });
    },

    updateCanvasImage(e) {
      this.showImage = true;
      var self = this;

      var reader,
        files = e.target.files;

      reader = new FileReader();

      reader.onload = e => {
        this.e = e;
        var img = new Image();
        img.onload = function () {
          self.drawCanvasImage(img);
        };
        img.src = e.target.result;
      };

      reader.readAsDataURL(files[0]);
    },

    drawCanvasImage(imageObj) {
      // const canvas = document.createElement("canvas");

      // canvas.width = imageObj.width;
      // canvas.height = imageObj.height;

      // var context = canvas.getContext("2d");
      // // ctx.drawImage(img, 0, 0,img.width/2,img.height/2);
      // context.drawImage(imageObj, 0, 0);

      // // var canvas = document.getElementById("imageCanvas");
      // var base64 = canvas.toDataURL("image/png");
      // this.image_base64 = base64;

      var canvas = this.$refs.imageCanvas;
      canvas.width = 120;
      canvas.height = (100 * imageObj.height) / imageObj.width;

      var context = canvas.getContext("2d");
      // ctx.drawImage(img, 0, 0,img.width/2,img.height/2);
      context.drawImage(
        imageObj,
        0,
        0,
        120,
        (100 * imageObj.height) / imageObj.width
      );

      // var canvas = document.getElementById("imageCanvas");
      var base64 = canvas.toDataURL("image/png");
      this.image = base64;
    }
  }
};
</script>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
