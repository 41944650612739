<style lang="scss">
.wiz_step {
  // margin-top: -10px;
  display: inline-flex;
  list-style: none;
  width: 100%;
  // background: #e3e3e3;
  height: 5px;
  .wiz-sub_step {
    margin-right: 0.5rem;
    width: calc(80% / 6);
    background: #e3e3e3;
    border-radius: 10px;
    height: 5px;
    &.current {
      background: #1bc5bd;
    }
    &.active {
      background: #a5dbd8;
    }
  }
}
.alert-danger-dim {
  background: #f7cece90;
}
.alert-success-dim {
  background-color: #b2facb70;
  border: 0;

  color: black;
}
#f-book-now {
  .alert-danger-dim {
    background: #f7cece90;
  }
  .table-bg-success {
    background: #b2facb70;
    border-radius: 10px;
  }
  p {
    font-size: 12px;
  }
  .abbre-code {
    display: flex;
    label {
      line-height: 50px;
      width: 255px;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .card {
    .card-body {
      padding: 1.25rem;
      .btn-right {
        float: right;
      }
      .cleft {
        float: left;
      }
    }
  }
  .live-active {
    border: 2px solid red;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
</style>
<template>
  <section class="content d-flex flex-column flex-column-fluid" id="f-book-now">
    <div class="full-load" v-if="saveDataLoader">
      <h1 v-html="loadingMessage"></h1>
    </div>
    <div class="d-flex flex-column-fluid">
      <div class="container-fluid mb-25">
        <div class="row mb-3">
          <div class="col-lg-10 col-md-12 col-12 offset-lg-1 offset-0">
            <v-title
              :title="getfClientName"
              :has-icon="true"
              :icon="'fa fa-map-marker'"
            ></v-title>
            <v-title
              :title="getfClientRate.service.name"
              :has-icon="true"
              :icon="'fa fa-cogs'"
            ></v-title>
            <div
              v-if="isRunning"
              style="float: right;margin-top: -3%;font-weight: 500;font-size: 15px !important;"
            >
              Time Left: {{ prettyTime }}
            </div>

            <ul class="wiz_step">
              <li
                class="wiz-sub_step"
                v-for="(witem, index) in wizardStep"
                :key="index"
                :class="
                  witem.active_status
                    ? 'active'
                    : witem.current_status
                    ? 'current'
                    : ''
                "
              ></li>
            </ul>
          </div>
        </div>
        <canvas
          id="barcode"
          style="
            width: inherit;
            height: 75px;
            padding: 0 10px 0 0;
            display: none;
          "
        ></canvas>
        <!-- slot -->
        <!-- booknow form where step =0 -->
        <div class="row">
          <div class="col-lg-10 col-md-12 col-12 offset-lg-1 offset-0">
            <div
              v-if="
                (errorMsg != '' && currentStep == 5) ||
                  (errorMsg != '' && currentStep == 4 && getIsDrugTesting)
              "
              role="alert"
              class="
                alert alert-custom alert-danger-dim alert-shadow
                fade
                show
                gutter-b
                alert-fixed
              "
            >
              <div class="alert-text" style="color: white;">
                {{ errorMsg }}
              </div>
            </div>
            <b-modal
              id="otp"
              no-close-on-backdrop
              centered
              size="lg"
              v-model="openOtpmodal"
              :title="
                !getsmsverification ? 'Verify Phone OTP' : 'Verify Email OTP'
              "
            >
              <div class="row">
                <div class="col-6" v-if="!getsmsverification">
                  <img
                    src="@/assets/images/otp/phoneotp.jpg"
                    height="300px"
                    width="300px"
                  />
                </div>

                <div
                  class="col-6"
                  style="padding: 10px"
                  v-if="!getsmsverification"
                >
                  <div
                    class="col-12"
                    style="padding: 10px;font-weight: 700;"
                    v-if="!getsmsverification"
                  >
                    Please verify with the code sent to your contact number
                  </div>
                  <div class="form-group col-6">
                    <label for="">OTP <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      name=""
                      class="form-control"
                      id=""
                      v-mask="'######'"
                      v-model="otp.otp_phone"
                      required
                    />
                    <span v-if="otp.is_phone" class="text-danger"
                      >OTP required</span
                    >
                  </div>
                  <div class="form-group col-12 mt-5">
                    Didn't recieve the code??
                    <button
                      class="btn btn-sm btn-info"
                      @click="ResendPhoneOtp()"
                      type="button"
                    >
                      Resend
                    </button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div
                  class="col-6"
                  v-if="!getemailverification && getsmsverification"
                >
                  <img
                    src="@/assets/images/otp/emailotp.jpg"
                    height="300px"
                    width="300px"
                  />
                </div>
                <div
                  class="col-6"
                  style="padding: 10px"
                  v-if="!getemailverification && getsmsverification"
                >
                  <div
                    class="col-12"
                    style="padding: 10px; font-weight: 700;"
                    v-if="!getemailverification && getsmsverification"
                  >
                    Please verify with the code sent to your email
                  </div>
                  <div class="form-group col-6">
                    <label for="">OTP <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      name=""
                      class="form-control"
                      id=""
                      v-model="otp.otp_email"
                      v-mask="'######'"
                    />
                    <span v-if="otp.is_email" class="text-danger"
                      >OTP required</span
                    >
                  </div>
                  <div class="form-group col-12 mt-5">
                    Didn't recieve the code??
                    <button
                      class="btn btn-sm btn-info"
                      @click="ResendEmailOtp()"
                      type="button"
                    >
                      Resend
                    </button>
                  </div>
                </div>
              </div>

              <template #modal-footer>
                <div class="w-100">
                  <b-button
                    @click="otpverify()"
                    v-if="getsmsverification == false"
                    variant="success"
                    size="sm"
                    class="float-right ml-5"
                  >
                    Verify
                  </b-button>
                  <b-button
                    v-if="
                      getemailverification == false &&
                        getsmsverification == true
                    "
                    @click="otpemailverify()"
                    variant="success"
                    size="sm"
                    class="float-right ml-5"
                  >
                    Verify
                  </b-button>
                </div>
              </template>
            </b-modal>

            <!--Apostille Service-->
            <apoContact
              v-if="currentStep == 0 && onlyApostilleService"
            ></apoContact>

            <apo-delivery-address 
              v-if="currentStep == 1 && onlyApostilleService"
              >
            </apo-delivery-address>

            <apo-doc-upload 
              v-if="currentStep == 2 && onlyApostilleService"
            >
            </apo-doc-upload>
            <apoRateSelect
              :show_page="currentStep == 3 ? 1 : (currentStep == 4 ? 2 : 0)"
              v-if="(currentStep == 3 || currentStep == 4) && onlyApostilleService"
            >
            </apoRateSelect>
            <!-- <f-book-now
              v-if="currentStep == 3 && onlyApostilleService"
              :key="'onlyApostilleService3'"
            ></f-book-now> -->
            <f-payment
              v-if="currentStep == 5 && onlyApostilleService"
              :key="'onlyApostilleService4'"
            ></f-payment>

            <!--Default-->
            <f-book-now
              v-if="currentStep == 0 && !onlyApostilleService"
              @startclock="startTime"
              :key="'original-finger0'"
            ></f-book-now>
            <f-calender
              v-if="
                currentStep == 1 &&
                  !getIsServiceByMail &&
                  getAppType != 'card scan' &&
                  !getIsApostilleService
              "
              :key="'original-finger1'"
            ></f-calender>
            <f-contact
              v-if="currentStep == 2 && onlyFingerScanForm"
              :key="'original-finger2'"
              :errorMsg="errorMsg"
            ></f-contact>
            <f-personal
              v-if="currentStep == 3 && onlyFingerScanForm"
              :key="'original-finger3'"
              :errorMsg="errorMsg"
            ></f-personal>
            <f-confirm-detail
              v-if="currentStep == 4 && onlyFingerScanForm"
              :key="'original-finger4'"
            >
            </f-confirm-detail>
            <f-payment
              v-if="currentStep == 5 && onlyFingerScanForm"
              :key="'original-finger5'"
            ></f-payment>
            <!-- only if drug testing-->

            <drug-contact
              v-if="currentStep == 2 && onlyDrugTesting"
              :key="'onlyDrugTesting3'"
            ></drug-contact>
            <f-confirm-detail
              v-if="currentStep == 3 && onlyDrugTesting"
              :key="'onlyDrugTesting4'"
            >
            </f-confirm-detail>
            <f-payment
              v-if="currentStep == 4 && onlyDrugTesting"
              :key="'onlyDrugTesting5'"
            ></f-payment>

            <!-- only if is service by  mail no calender -->

            <f-contact
              v-if="currentStep == 1 && onlyServiceByMail"
              :key="'onlyServiceByMail1'"
              :errorMsg="errorMsg"
            ></f-contact>
            <f-contact
              v-if="currentStep == null && onlyServiceByMail"
              :key="'onlyServiceByMail1'"
              :errorMsg="errorMsg"
            ></f-contact>
            <f-personal
              v-if="currentStep == 2 && onlyServiceByMail"
              :key="'onlyServiceByMail2'"
              :errorMsg="errorMsg"
            ></f-personal>

            <f-confirm-detail
              v-if="currentStep == 3 && onlyServiceByMail"
              :key="'onlyServiceByMail3'"
            >
            </f-confirm-detail>
            <f-payment
              v-if="currentStep == 4 && onlyServiceByMail"
              :key="'onlyServiceByMail4'"
            ></f-payment>

            <drug-contact
              v-if="currentStep == 1 && bothServiceByMailDrugTesting"
              :errorMsg="errorMsg.data"
              :key="'bothServiceByMailDrugTesting2'"
            ></drug-contact>
            <f-confirm-detail
              v-if="currentStep == 2 && bothServiceByMailDrugTesting"
              :key="'onlyServiceByMail3'"
            >
            </f-confirm-detail>
            <f-payment
              v-if="currentStep == 3 && bothServiceByMailDrugTesting"
              :key="'onlyServiceByMail4'"
            ></f-payment>
          </div>
        </div>
      </div>

      <v-form-footer
        v-if="onlyApostilleService"
        v-on:next="nextStep"
        v-on:back="back"
        :nextbtnicon="nextBtnIcon"
        :totalamount="getTotalServiceAmount | toFixedTwoDigit(getfClientRate)"
        :btnname="nextBtn"
        :backname="'Back'"
      ></v-form-footer>
      <v-form-footer
        v-else-if="getfClientRate && !getEnableOriRate"
        v-on:next="nextStep"
        v-on:back="back"
        :nextbtnicon="nextBtnIcon"
        :totalamount="getTotalServiceAmount | toFixedTwoDigit(getfClientRate)"
        :btnname="nextBtn"
        :backname="'Back'"
      ></v-form-footer>
      <v-form-footer
        v-else-if="
          getfClientRate &&
            getEnableOriRate &&
            getServiceOriCode.success == true
        "
        v-on:next="nextStep"
        v-on:back="back"
        :nextbtnicon="nextBtnIcon"
        :totalamount="getTotalServiceAmount | toFixedTwoDigit(getfClientRate)"
        :btnname="nextBtn"
        :backname="'Back'"
      ></v-form-footer>
      <v-form-footer
        v-else
        v-on:next="nextStep"
        v-on:back="back"
        :nextbtnicon="nextBtnIcon"
        :btnname="nextBtn"
        :backname="'Back'"
      ></v-form-footer>
    </div>
  </section>
</template>
<script>
// import * as PDF417 from "pdf417-generator";
import vTitle from "@/components/frontend/title";
import vFormFooter from "@/components/frontend/formfooter";
import fBookNow from "./booknow.vue";
import fCalender from "./calender.vue";
import fContact from "./contact.vue";
import drugContact from "./drugcontact.vue";

import apoDocUpload from "./Apostille/DocumentUpload.vue";
import apoDeliveryAddress from "./Apostille/DeliveryAddress.vue";
import apoContact from "./Apostille/Contact.vue";
import apoRateSelect from "./Apostille/RateSelect.vue";


import fPersonal from "./personal.vue";
import fConfirmDetail from "./confirm.vue";
import fPayment from "./payment.vue";
import { mapGetters } from "vuex";
import {
  STORE_APPOINTMENT_FROM_APPLICANT,
  MAKE_INVOICE_AFTER_ONLINE_PAYEMENT,
  SUCCESS_DRUG_TESTING_PAYEMENT,
  STORE_APPOINTMENT_APPLICANT_INFO,
  STORE_APPOINTMENT_APPLICANT_INFO_ID,
  STORE_APPLICANT_PERSONAL_INFO,
  STORE_APPOINTMENT,
  STORE_APPOINTMENT_ID,
  STORE_DRUG_APPOINTMENT_APPLICANT_INFO,
  OTP_VERIFICATION,
  VERIFY_OTP_SMS,
  VERIFY_OTP_EMAIL,
  RESEND_OTP_EMAIL,
  RESEND_OTP_SMS,
  SUBMIT_APOSTILLE_APPOINTMENT,
  SUBMIT_APOSTILLE_PAYMENT,
  FETCH_F_CLIENT_RATE,
  FETCH_F_AVAILABLE_COUNTRY,
} from "@/core/services/store/actions.type";

import {
  SET_APPOINTMENT_TIME,
  UPDATE_FIRSTNAME,
  UPDATE_MIDDLENAME,
  UPDATE_LASTNAME,
  UPDATE_EMAIL,
  UPDATE_CONFIRMEMAIL,
  UPDATE_PHONENO,
  UPDATE_ADDRESSLINE1,
  UPDATE_ADDRESSLINE2,
  UPDATE_ZIPCODE,
  UPDATE_REASONFORFINGERPRINT,
  UPDATE_STATE,
  UPDATE_CITY,
  UPDATE_ORI,
  UPDATE_SOCIAL_SECURITY,
  UPDATE_WEIGHT,
  UPDATE_PLACE_OF_BIRTH,
  UPDATE_COUNTRY,
  UPDATE_GENDER,
  UPDATE_RACE,
  UPDATE_EYECOLOR,
  UPDATE_HAIRCOLOR,
  UPDATE_DATEOFBIRTH,
  UPDATE_DATEOFBIRTH_MONTH,
  UPDATE_DATEOFBIRTH_DAY,
  UPDATE_DATEOFBIRTH_YEAR,
  UPDATE_HEIGHT,
  SET_SMS_STATUS,
  UPDATE_ABBREVIATION_CODE,
  SET_PAYMENT_CARD_PROPERTY,
  SET_PAYMENT_CARD_TYPE,
  SET_PAYMENT_CARD_NUMBER,
  SET_PAYMENT_CARD_HOLDER_NAME,
  SET_PAYMENT_CARD_ADDRESS,
  SET_PAYMENT_CARD_CITY,
  SET_PAYMENT_CARD_CVV_NUMBER,
  SET_PAYMENT_CARD_EXPIRY_DATE,
  CARD_COUNTRY,
  CARD_STATE,
  SET_APPOINTMENT_ID,
  SET_APPLICATION_INFO_ID,
  SET_APPT_TOKEN,
  SET_F_CLIENT_RATE,
  UPDATE_PAYMENT_TYPE,
  SET_PAYMENT_CARD_NUMBER_PHONE_CODE,
  SET_COUNTRY_PHONE_CODE,
  SET_PAYMENT_CARD_NUMBER_POSTAL,
  SET_PAYMENT_CARD_NUMBER_PHONE,
  SET_APPICANTS,
  SET_QTY,
  SET_IS_DRUG_TESTING,
  SET_SHOW_CAPTURE_OPTION,
  SET_MULTI_RECIPIENT,
  SET_IS_SERVICE_BY_MAIL,
  SET_CHECK_F_ABBREVIATION_CODE,
  SET_F_ARCHIVAL_CLIENT_RATE,
  UPDATE_SERVICE_ORI_CODE,
  SET_CHECK_F_SERVICE_ORI_CODE,
  UPDATE_PROMO_CODE,
  SET_CHECK_F_PROMO_CODE,
  SET_PROMOCODE_DISCOUNT_PERCENTAGE,
  SET_PROMOCODE_DISCOUNT_AMOUNT,
  SET_ARCHIVAL_PERIOD_ID,
  SET_ARCHIVAL_TOTAL_RATE_AMOUNT,
  SET_ADDITIONAL_CARD_TOTAL_AMOUNT,
  SET_OTP_FOR_SMS_VERIFY,
  SET_OTP_FOR_EMAIL_VERIFY,
  SET_APP_TYPE,
  SET_DOC_TRANSLATION_RATE_STATUS,
  SET_DOC_SCAN_RATE_STATUS,
  SET_DOCUMENT_COUNTRY,
  SET_DELIVERY_ADDRESS_NAME,
  SET_DELIVERY_ADDRESS_COMPANY_NAME,
  SET_DELIVERY_ADDRESS_ADDRESS,
  SET_DELIVERY_ADDRESS_CITY,
  SET_DELIVERY_ADDRESS_POSTAL_CODE,
  SET_DELIVERY_ADDRESS_STATE,
  SET_DELIVERY_ADDRESS_COUNTRY,
  SET_DELIVERY_ADDRESS_CONTACT,
  SET_DELIVERY_ADDRESS_PHONE_CODE,
  SET_TOTAL_SERVICE_AMOUNT,
  SET_IS_SELECTED_EXPEDITED_RATE,
  SET_APOSTILLE_SUBMITTED_DATA,
  SET_DOCUMENT_LIST,
  SET_IS_SIGNATURE_REQUIRED
} from "@/core/services/store/mutations.type";
// import Title from '../../components/frontend/title.vue';
export default {
  components: {
    vTitle,
    vFormFooter,
    fBookNow,
    fCalender,
    fContact,
    fPersonal,
    fConfirmDetail,
    fPayment,
    drugContact,
    apoDocUpload,
    apoDeliveryAddress,
    apoContact,
    apoRateSelect
  },
  data() {
    return {
      totalStep: 6,
      starttime: 0,
      timer: null,
      isRunning: false,
      wizardStep: [
        {
          title: "Appointment Pricing",
          active_status: false,
          current_status: false
        },
        {
          title: "Appointment Date",
          active_status: false,
          current_status: false
        },
        {
          title: "Applicant Contact Information",
          active_status: false,
          current_status: false
        },
        {
          title: "Applicant Personal Identity Information",
          active_status: false,
          current_status: false
        },
        {
          title: "Appointment Confirm Details",
          active_status: false,
          current_status: false
        },
        {
          title: "Appointment Payment",
          active_status: false,
          current_status: false
        }
      ],
      regEmail: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      theValue: "",
      nextBtnIcon: "fa-arrow-right",
      saveDataLoader: false,
      currentStep: 0,
      nextBtn: "Book Now",
      errorMsg: [],
      loadingMessage: "",
      openOtpmodal: false,
      otp: {
        otp_phone: "",
        is_phone: false,
        otp_email: "",
        is_email: false
      },
      message:
        "Please be advised that Fingerscan can provide FD258 & FINRA cards on-site or via shipping, depending on the location. If you need your fingerprints on a different card, please contact info@fingerscan.com AFTER completing your appointment for specific instructions.",
      
    };
  },
  filters: {
    toFixedTwoDigit(amount) {
      // var rate = parseFloat(data.rate) * parseInt(data.service.default_qty);
      // // var rate = parseFloat(data.rate);
      // var tp = parseFloat(data.tax_percentage);
      return parseFloat(amount).toFixed(2);
    }
  },
  computed: {
    prettyTime() {
      let time = this.starttime / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      let time_limit =
        this.getServiceProviderData.warning_time == null
          ? 20
          : this.getServiceProviderData.warning_time.time_limit;
      if (secondes + minutes * 60 == time_limit * 60) {
        this.Showwarning();
      }
      return (
        String(minutes).padStart(2, 0) + ":" + String(secondes).padStart(2, 0)
      );
    },
    ...mapGetters([
      "getCitizenTitle",
      "getServiceType",
      "getfClientName",
      "getfServiceSlug",
      "getTotalServiceAmount",
      "getfClientRate",
      "getfrontAppointmentTime",
      "getApplicantFirstName",
      "getApplicantMiddleName",
      "getApplicantlastName",
      "getApplicantEmail",
      "getApplicantConfirmEmail",
      "getApplicantPhoneNo",
      "getApplicantAddressLine1",
      "getApplicantAddressLine2",
      "getApplicantZipCode",
      "getApplicantReason",
      "getApplicantCity",
      "getApplicantState",
      "getApplicantWeight",
      "getApplicantSSN",
      "getApplicantPlaceOfBirth",
      "getApplicantcounrtyOfCitizeb",
      "getApplicantGender",
      "getApplicantRace",
      "getApplicantEyeColor",
      "getApplicantHairColor",
      "getApplicantDateOfBirth",
      "getApplicantHeight",
      "getServiceId",
      "getPaymentType",
      "getSelectedTime",
      "getTimeZone",
      "getfrontAppointmentDate",
      "getSmsStatus",
      "getAbbreviationCode",
      "updateAbbreviationCode",
      "getAddQty",
      "getPaymentCardNumber",
      "getPaymentCardHolderName",
      "getPaymentCardExpiry",
      "getPaymentCardCvvNumber",
      "getPaymentCardType",
      "getSelectedCountryPhoneCode",
      "getPaymentCardHolderAddress",
      "getPaymentCardHolderCountry",
      "getPaymentCardHolderState",
      "getPaymentCardHolderCity",
      "getAppointmentId",
      "getApplicantId",
      "getApplicantDobMonth",
      "getApplicantDobDay",
      "getApplicantDobYear",
      "getApptToken",
      "getPaymentCardPhonecode",
      "getPaymentCardPhone",
      "getPaymentCardPostalZip",
      "getQty",
      "getMultirecipient",
      "getIsDrugTesting",
      "getApplicants",
      "getIsHeartsPayment",
      "getIsServiceByMail",
      "getServiceProviderData",
      "getArchivalPeriodId",
      "getApplicantOri",
      "getServiceTypeCode",
      "getServiceOriCode",
      "getServiceOri",
      "getPromoCode",
      "updatePromoCode",
      "getArchivalPeriodId",
      "getTotalDiscountAmount",
      "getPromoCodeDiscountRate",
      "getsmsverification",
      "getemailverification",
      "getAppType",
      "getEnableOriRate",
      "getIsApostilleService",
      "getDeliveryAddress",
      "getDocumentInformationList",
      "getSelectedMailer",
      "getTranslationRateStatus",
      "getScanRateStatus",
      "getPaymentDetails",
      "updateServiceOriCode",
      "getTotalAdditionalAmount",
      "getDocumentCountry",
      "getIsExpeditedRate",
      "getApostilleSubmittedData",
      "getIsSignatureRequired"
    ]),
    onlyDrugTesting() {
      return (
        this.getIsDrugTesting &&
        !this.getIsServiceByMail &&
        this.getIsApostilleService &&
        this.getAppType != "card scan"
      );
    },
    onlyServiceByMail() {
      return (
        !this.getIsDrugTesting &&
        !this.getIsApostilleService &&
        (this.getIsServiceByMail || this.getAppType == "card scan")
      );
    },
    bothServiceByMailDrugTesting() {
      return (
        this.getIsDrugTesting &&
        !this.getIsApostilleService &&
        (this.getIsServiceByMail || this.getAppType == "card scan")
      );
    },
    onlyFingerScanForm() {
      return (
        !this.getIsDrugTesting &&
        !this.getIsServiceByMail &&
        !this.getIsApostilleService &&
        this.getAppType != "card scan"
      );
    },
    onlyApostilleService() {
      return (
        !this.getIsDrugTesting &&
        !this.getIsServiceByMail &&
        this.getIsApostilleService &&
        this.getAppType != "card scan"
      );
    }
  },
  mounted() {
    this.titleOfNext(this.currentStep);
    this.wizardStep[this.currentStep].current_status = true;
    if (this.getServiceId == 0) {
      // this.$toastr.e("You have not select service");
      this.$router.push({
        name: "frontend.home",
        params: { slug: this.$route.params.client_slug }
      });
    }
    this.$store.commit(SET_CHECK_F_ABBREVIATION_CODE, {
      data: {
        show_payment_option: true
      },
      success: "none"
    });

    if(this.getIsApostilleService){
      this.fetchRate();
    }
  },
  destroyed() {
    clearInterval(this.timer);
  },
  created() {
    if (this.getIsApostilleService) {
      this.totalStep = 5;
      this.wizardStep = [
        {
          title: "Appointment Contact Info",
          active_status: false,
          current_status: false
        },
        {
          title: "Appointment Document Info",
          active_status: false,
          current_status: false
        },
        {
          title: "Appointment Delivery Address",
          active_status: false,
          current_status: false
        },
        {
          title: "Appointment Rate Select",
          active_status: false,
          current_status: false
        },
        {
          title: "Appointment Additional Rate Select",
          active_status: false,
          current_status: false
        },
        {
          title: "Appointment Payment",
          active_status: false,
          current_status: false
        },
      ]
    };
    if (this.getIsDrugTesting) {
      this.totalStep = 5;
      this.wizardStep = [
        {
          title: "Appointment Pricing",
          active_status: false,
          current_status: false
        },
        {
          title: "Appointment Date",
          active_status: false,
          current_status: false
        },
        {
          title: "Applicant Contact Information",
          active_status: false,
          current_status: false
        },
        {
          title: "Appointment Confirm Details",
          active_status: false,
          current_status: false
        },
        {
          title: "Appointment Payment",
          active_status: false,
          current_status: false
        }
      ];
    }
    if (this.onlyServiceByMail) {
      this.totalStep = 4;
      this.wizardStep = [
        {
          title: "Appointment Pricing",
          active_status: false,
          current_status: false
        },
        {
          title: "Applicant Contact Information",
          active_status: false,
          current_status: false
        },
        {
          title: "Applicant Personal Identity Information",
          active_status: false,
          current_status: false
        },
        {
          title: "Appointment Confirm Details",
          active_status: false,
          current_status: false
        },
        {
          title: "Appointment Payment",
          active_status: false,
          current_status: false
        }
      ];
    }
    if (this.bothServiceByMailDrugTesting) {
      this.wizardStep = [
        {
          title: "Appointment Pricing",
          active_status: false,
          current_status: false
        },
        {
          title: "Applicant Contact Information",
          active_status: false,
          current_status: false
        },
        {
          title: "Appointment Confirm Details",
          active_status: false,
          current_status: false
        },
        {
          title: "Appointment Payment",
          active_status: false,
          current_status: false
        }
      ];
    }
  },
  watch: {
    currentStep: {
      handler(newCurrentStep) {
        if(this.onlyApostilleService){
          if(this.currentStep == 4){
            this.titleOfNext(4);
          }
          else if(this.currentStep == 5){
            this.titleOfNext(5);
          }
        }
        else{
          if (this.onlyDrugTesting && newCurrentStep == 3) {
            this.titleOfNext(4);
          } else if (this.onlyDrugTesting && newCurrentStep == 4) {
            this.titleOfNext(5);
          } else if (this.onlyServiceByMail && newCurrentStep == 3) {
            this.titleOfNext(4);
          } else if (this.onlyServiceByMail && newCurrentStep == 4) {
            this.titleOfNext(5);
          } else if (this.bothServiceByMailDrugTesting && newCurrentStep == 2) {
            this.titleOfNext(4);
          } else if (this.bothServiceByMailDrugTesting && newCurrentStep == 3) {
            this.titleOfNext(5);
          } else {
            this.titleOfNext(newCurrentStep);
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    startTime() {
      if (!this.isRunning) {
        this.isRunning = true;
        let time_limit =
          this.getServiceProviderData.expiry_time == null
            ? 30
            : this.getServiceProviderData.expiry_time.time_limit;
        this.starttime = time_limit * 60;

        this.start();
      }
    },
    isValidDate(year, month, day) {
      var d = new Date(year, month - 1, day);
      return month == d.getMonth() + 1;
    },
    Showwarning() {
      this.$swal({
        title:
          this.getServiceProviderData.warning_time == null
            ? "Timeout Warning"
            : this.getServiceProviderData.warning_time.title,
        text:
          this.getServiceProviderData.warning_time == null
            ? "You have 10 minutes left to complete your appointment"
            : this.getServiceProviderData.warning_time.warning_msg,

        confirmButtonText: "ok",
        cancelButtonText: "Cancel",
        showCancelButton: false,
        showCloseButton: true,
        reverseButtons: true,
        allowOutsideClick: false
      });
    },
    start() {
      if (this.isRunning) {
        this.timer = setInterval(() => {
          if (this.starttime > 0) {
            this.starttime--;
          } else {
            this.timeoutalert();
          }
        }, 1000);
      }
    },
    timeoutalert() {
      clearInterval(this.timer);
      this.isRunning = false;
      this.$swal({
        title:
          this.getServiceProviderData.expiry_time == null
            ? "Timeout"
            : this.getServiceProviderData.expiry_time.title,
        text:
          this.getServiceProviderData.expiry_time == null
            ? "You have exceeded 30 minute timeout limit, please try again!"
            : this.getServiceProviderData.expiry_time.warning_msg,
        confirmButtonText: "ok",
        cancelButtonText: "Cancel",
        showCancelButton: false,
        showCloseButton: true,
        reverseButtons: true,
        allowOutsideClick: false
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          this.reset();
          this.$store.commit(UPDATE_ORI, "");
          //  this.$store.commit(UPDATE_PAYMENT_TYPE,this.getPaymentType);

          this.$router.push({
            name: "frontend.home",
            params: { slug: this.$route.params.client_slug }
          });
        }
        if (willDelete.isDismissed) {
          this.reset();
          this.$router.push({
            name: "frontend.home",
            params: { slug: this.$route.params.client_slug }
          });
        }
      });
    },
    reset() {
      this.$store.commit(SET_APPOINTMENT_TIME, []);
      this.$store.commit(UPDATE_FIRSTNAME, "");
      this.$store.commit(UPDATE_MIDDLENAME, "");
      this.$store.commit(UPDATE_LASTNAME, "");
      this.$store.commit(UPDATE_EMAIL, "");
      this.$store.commit(UPDATE_CONFIRMEMAIL, "");
      this.$store.commit(UPDATE_PHONENO, "");
      this.$store.commit(UPDATE_ADDRESSLINE1, "");
      this.$store.commit(UPDATE_ADDRESSLINE2, "");
      this.$store.commit(UPDATE_ZIPCODE, "");
      this.$store.commit(UPDATE_REASONFORFINGERPRINT, "");
      this.$store.commit(UPDATE_STATE, "");
      this.$store.commit(UPDATE_CITY, "");
      this.$store.commit(UPDATE_SOCIAL_SECURITY, "");
      this.$store.commit(UPDATE_WEIGHT, "");
      this.$store.commit(UPDATE_ORI, "");
      this.$store.commit(UPDATE_SERVICE_ORI_CODE, "");
      this.$store.commit(SET_PROMOCODE_DISCOUNT_PERCENTAGE, 0);
      this.$store.commit(SET_PROMOCODE_DISCOUNT_AMOUNT, 0);
      this.$store.commit(SET_ARCHIVAL_PERIOD_ID, "");
      this.$store.commit(SET_ADDITIONAL_CARD_TOTAL_AMOUNT, 0);
      this.$store.commit(SET_ARCHIVAL_TOTAL_RATE_AMOUNT, 0);
      this.$store.commit(SET_CHECK_F_SERVICE_ORI_CODE, {
        data: "",
        success: "none"
      });

      this.$store.commit(SET_CHECK_F_PROMO_CODE, {
        data: { discount_percentage: 0 },
        success: "none"
      });
      this.$store.commit(UPDATE_PLACE_OF_BIRTH, "");
      this.$store.commit(UPDATE_COUNTRY, "United States");
      this.$store.commit(UPDATE_GENDER, "");
      this.$store.commit(UPDATE_RACE, "");
      this.$store.commit(UPDATE_EYECOLOR, "");
      this.$store.commit(UPDATE_HAIRCOLOR, "");
      this.$store.commit(UPDATE_DATEOFBIRTH, "");
      this.$store.commit(UPDATE_DATEOFBIRTH_MONTH, "");
      this.$store.commit(UPDATE_DATEOFBIRTH_DAY, "");
      this.$store.commit(UPDATE_DATEOFBIRTH_YEAR, "");
      this.$store.commit(UPDATE_HEIGHT, "");
      this.$store.commit(SET_SMS_STATUS, false);
      this.$store.commit(UPDATE_ABBREVIATION_CODE, "");
      this.$store.commit(UPDATE_PROMO_CODE, "");
      this.$store.commit(SET_PAYMENT_CARD_PROPERTY, "");
      this.$store.commit(SET_PAYMENT_CARD_TYPE, "");
      this.$store.commit(SET_PAYMENT_CARD_NUMBER, "");
      this.$store.commit(SET_PAYMENT_CARD_HOLDER_NAME, "");
      this.$store.commit(SET_PAYMENT_CARD_ADDRESS, "");
      this.$store.commit(SET_PAYMENT_CARD_CITY, "");
      this.$store.commit(SET_PAYMENT_CARD_CVV_NUMBER, "");
      this.$store.commit(SET_PAYMENT_CARD_EXPIRY_DATE, "");
      this.$store.commit(CARD_COUNTRY, "United States");
      this.$store.commit(CARD_STATE, "");
      this.$store.commit(CARD_STATE, "");
      this.$store.commit(SET_APPOINTMENT_ID, 0);
      this.$store.commit(SET_APPLICATION_INFO_ID, 0);
      this.$store.commit(SET_APPT_TOKEN, 0);
      this.$store.commit(SET_F_CLIENT_RATE, {
        rate: "0",
        additional_rate: "0",
        service: {
          default_qty: 0
        }
      });

      this.$store.commit(UPDATE_PAYMENT_TYPE, "C");
      this.$store.commit(SET_PAYMENT_CARD_NUMBER_PHONE, "");
      this.$store.commit(SET_PAYMENT_CARD_NUMBER_POSTAL, "");
      this.$store.commit(SET_APPICANTS, []);
      this.$store.commit(SET_QTY, 0);
      this.$store.commit(SET_IS_DRUG_TESTING, false);
      this.$store.commit(SET_SHOW_CAPTURE_OPTION, false);
      this.$store.commit(SET_MULTI_RECIPIENT, 0);
      this.$store.commit(SET_F_ARCHIVAL_CLIENT_RATE, "");
      this.$store.commit(SET_APP_TYPE, "");

      // eslint-disable max-len
      var country_phone_code = {
        name: "United States",
        dial_code: "+1",
        code: "US",
        flag:
          // eslint-disable-next-line max-len
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAIWSURBVHja1JY/SBthGIefu1xqS6K20KFDy0kopUiHmphIByUZotRAIZOTWZzFpYtbB0uh6KJTIdQhi9pBSwmmCOpgoUSKFItTh4AU6tCr16Rn5P58XZocDrlYuAz9wfHAcbzv9/2+932/k4QQdFIyHVbHE0iAAlwFgj7HNoG6AoRzudc/A4F/28yL2l7bb269yd9QgJAsS8zMjFIufyWRuHspXqtbnsHrH8oAIQlQJyfzlaGhCNFohJ2dI1Kp/iZ3d49IJvsvvJckmJ197JlACIEsy30KgGUJBgcjFIufSacfsLnpza2tL/x4+qx15fR0Uz84hL8HjG1blEqHJJP9bGx8IpMZ8CSAMIzWq1cUhO24CSzLYWTkPisrH8lm46yuenN9fZ+br156WmRZFgQLjR3YrK2VyWSiFAp7TEw88iTAyZNca4t6e6h/P3EbzTRtxscfks9vk83G27JaPcOuVls/v6o4pltlajo9L1KpebG8vC9isbm2jMXmRDsZhiEAVWn4NTU1ysJCkenpMRYXS55cWnrPcSThUUVhzrquNEeFOjz8vOI4CrXa+aU7+d3p29YJusMYwQD3Drb7AFRd14Xf0nXdtehbfAxdkhG13/5M0HCImiTcPhC2BVIAHMefOWrbCNxYqqZpvlukaVrTIrNye4CK1JH7xpSAXuAOcN3n4KfAceNG62qch4+ygHPpv/+r+DMAXV79BpyNnBoAAAAASUVORK5CYII="
      };
      this.$store.commit(
        SET_PAYMENT_CARD_NUMBER_PHONE_CODE,
        country_phone_code
      );
      this.$store.commit(SET_COUNTRY_PHONE_CODE, country_phone_code);
      this.$store.commit(SET_IS_SERVICE_BY_MAIL, false);
      this.$store.commit(SET_DOC_SCAN_RATE_STATUS, false);
      this.$store.commit(SET_DOC_TRANSLATION_RATE_STATUS, false);
      this.$store.commit(SET_DOCUMENT_LIST, []);
      this.$store.commit(SET_DOCUMENT_COUNTRY, -1);

      this.$store.commit(SET_DELIVERY_ADDRESS_NAME, "");
      this.$store.commit(SET_DELIVERY_ADDRESS_COMPANY_NAME, "");
      this.$store.commit(SET_DELIVERY_ADDRESS_ADDRESS, "");
      this.$store.commit(SET_DELIVERY_ADDRESS_CITY, "");
      this.$store.commit(SET_DELIVERY_ADDRESS_POSTAL_CODE, "");
      this.$store.commit(SET_DELIVERY_ADDRESS_STATE, "");
      this.$store.commit(SET_DELIVERY_ADDRESS_COUNTRY, "US");
      this.$store.commit(SET_DELIVERY_ADDRESS_CONTACT, "");
      this.$store.commit(SET_DELIVERY_ADDRESS_PHONE_CODE , country_phone_code);
      this.$store.commit(SET_IS_SELECTED_EXPEDITED_RATE, false)
      this.$store.commit(SET_APOSTILLE_SUBMITTED_DATA, {});
      this.$store.commit(SET_IS_SIGNATURE_REQUIRED, false);
    },
    validateAndStoreApplicantContactInfo() {
      if (
        this.getApplicantFirstName == "" ||
        this.getApplicantlastName == "" ||
        this.getApplicantEmail == "" ||
        this.getApplicantConfirmEmail == "" ||
        this.getApplicantPhoneNo == "" ||
        (this.getApplicantAddressLine1 == "" ) ||
        (this.getApplicantZipCode == "" ) ||
        (this.getApplicantCity == "") ||
        (this.getApplicantCity == null) ||
        (this.getApplicantState == "") ||
        (this.getApplicantState == null) ||
        (this.getApplicantReason == "")
      ) {
        this.$toastr.e("Please enter all mandatory fields ");
      } else {
        if (!this.regEmail.test(this.getApplicantEmail)) {
          this.$toastr.e("Please enter a valid email address");
        } else {
          if (this.getApplicantEmail != this.getApplicantConfirmEmail) {
            this.$toastr.e("Email and confirm email doesn't matched");
          } else {
            if (this.getApplicantPhoneNo.length != 14) {
              this.$toastr.e("Please enter valid phone number");
            } else {
              if (this.getApplicantZipCode.length != 5) {
                this.$toastr.e("Please enter valid zipcode");
              } else {
                this.storeApplicant();
              }
            }
          }
        }
      }
    },
    validateAndStorePersonalInfo() {
      if (
        this.getApplicantDobMonth == null ||
        this.getApplicantDobMonth == "" ||
        this.getApplicantDobDay == null ||
        this.getApplicantDobDay == "" ||
        this.getApplicantDobYear == null ||
        this.getApplicantDobYear == "" ||
        this.getApplicantPlaceOfBirth == "" ||
        this.getApplicantcounrtyOfCitizeb == "" ||
        this.getApplicantGender == "" ||
        this.getApplicantRace == "" ||
        this.getApplicantRace == null ||
        this.getApplicantEyeColor == "" ||
        this.getApplicantHairColor == "" ||
        this.getApplicantEyeColor == null ||
        this.getApplicantHairColor == null ||
        this.getApplicantHeight == "" ||
        this.getApplicantWeight == ""
      ) {
        this.$toastr.e("Please enter all mandatory fields");
      } else {
        if (
          !this.isValidDate(
            this.getApplicantDobYear,
            this.getApplicantDobMonth,
            this.getApplicantDobDay
          )
        ) {
          this.$toastr.e("Please Enter valid Date of birth");
        } else {
          if (this.getApplicantSSN != "") {
            if (this.getApplicantSSN.length < 11) {
              this.$toastr.e("Incomplete Social Security Number");
            } else {
              // this.currentStep = this.currentStep + 1;
              this.storeApplicantPersonalIdentity();
            }
          } else {
            this.storeApplicantPersonalIdentity();
          }
        }
      }
    },
    nextStep() {
      if(this.onlyApostilleService){
        if(this.validateApostilleData(this.currentStep)){
          this.currentStep = this.currentStep + 1;
        }
      }
      else if (this.currentStep == 0) {
        if (this.updateAbbreviationCode != "") {
          if (!this.getAbbreviationCode.success) {
            this.$toastr.e(this.getAbbreviationCode.msg);
          } else {
            if (this.getEnableOriRate) {
              if (this.getServiceOri == "") {
                if (this.getServiceOriCode.msg) {
                  this.$toastr.e(this.getServiceOriCode.msg);
                } else {
                  this.$toastr.e("Please confirm ORI");
                }
              } else {
                if (this.getIsServiceByMail || this.getAppType == "card scan") {
                  this.storeAppointment(true);
                } else {
                  this.currentStep = this.currentStep + 1;
                }
              }
            } else {
              if (this.getIsServiceByMail || this.getAppType == "card scan") {
                this.storeAppointment(true);
                // this.currentStep = this.currentStep + 1;
              } else {
                this.currentStep = this.currentStep + 1;
              }
            }
          }
        } else {
          if (this.getEnableOriRate) {
            if (this.getServiceOri == "") {
              if (this.getServiceOriCode.msg) {
                this.$toastr.e(this.getServiceOriCode.msg);
              } else {
                this.$toastr.e("Please confirm ORI");
              }
            } else {
              if (this.getIsServiceByMail || this.getAppType == "card scan") {
                this.storeAppointment(true);
              } else {
                this.currentStep = this.currentStep + 1;
              }
            }
          } else {
            if (this.getIsServiceByMail || this.getAppType == "card scan") {
              this.storeAppointment(true);
              // this.currentStep = this.currentStep + 1;
            } else {
              this.currentStep = this.currentStep + 1;
            }
          }
        }
        // this.currentStep = this.currentStep + 1;
      } else if (this.currentStep == 1) {
        if (this.onlyServiceByMail) {
          this.validateAndStoreApplicantContactInfo();
        } else if (this.bothServiceByMailDrugTesting) {
          let getApplicants = this.getApplicants;
          let error = 0;
          getApplicants.forEach(data => {
            if (data.applicant.email != data.applicant.cemail) {
              this.$toastr.e("Email and confirm email doesn't matched");
              error = 1;
              return 0;
            } else {
              error = 0;
            }
          });
          if (error == 0) {
            this.storeDrugApplicant();
          }
        } else {
          if (this.getfrontAppointmentTime == null) {
            //Please select date and time
            // Please selected the appropriate date and time
            this.$toastr.e("Please select date and time");
          } else {
            this.storeAppointment(false);
          }
        }
      } else if (this.currentStep == 2) {
        if (this.onlyDrugTesting) {
          //
          let getApplicants = this.getApplicants;
          let error = 0;
          getApplicants.forEach(data => {
            if (data.applicant.email != data.applicant.cemail) {
              this.$toastr.e("Email and confirm email doesn't matched");
              error = 1;
              return 0;
            } else {
              error = 0;
            }
          });
          if (error == 0) {
            this.storeDrugApplicant();
          }
        } else if (this.onlyServiceByMail) {
          this.validateAndStorePersonalInfo();
        } else if (this.bothServiceByMailDrugTesting) {
          if (this.getSmsStatus == false) {
            this.$toastr.e("Please confirm the given details ");
            return;
          } else {
            this.currentStep = this.currentStep + 1;
          }
        } else {
          this.validateAndStoreApplicantContactInfo();
        }
      } else if (this.currentStep == 3) {
        if (this.onlyDrugTesting || this.onlyServiceByMail) {
          if (this.getSmsStatus == false) {
            this.$toastr.e("Please confirm the given details ");
            return;
          }
          // getSmsStatus
          else {
            this.currentStep = this.currentStep + 1;
          }
        } else if (this.bothServiceByMailDrugTesting) {
          this.submitForPayment();
        } else {
          this.validateAndStorePersonalInfo();
        }
      } else if (this.currentStep == 4) {
        if (this.onlyDrugTesting) {
          // this.storeApplicantPersonalIdentity();
          this.submitForPayment();
        } else if (this.onlyServiceByMail) {
          this.paymentheartlandOrcardpointee();
        } else {
          if (this.getSmsStatus == false) {
            this.$toastr.e("Please confirm the given details ");
            return;
          } else {
            this.currentStep = this.currentStep + 1;
          }
        }
      } else if (this.currentStep == 5) {
        this.paymentheartlandOrcardpointee();
      }

      this.wizardStep[this.currentStep - 1].current_status = false;
      this.wizardStep[this.currentStep - 1].active_status = true;
      this.wizardStep[this.currentStep].current_status = true;

      this.currentStep = this.currentStep > 5 ? 0 : this.currentStep;
      if (this.currentStep == 0) {
        this.openService(1);
      }
    },
    paymentheartlandOrcardpointee() {
      var mytoken = document.getElementById("mytoken");
      mytoken = mytoken.innerHTML;
      if (this.updateAbbreviationCode != "") {
        if (!this.getAbbreviationCode.success) {
          this.$toastr.e(this.getAbbreviationCode.msg);
        } else {
          if (this.getPaymentType == "C") {
            this.loadingMessage =
              "please wait <br> While we process your payment...";

            if (mytoken == "" || mytoken == null) {
              this.$toastr.e(
                "The card number entered is not compliant, please check and re-enter."
              );
            } else {
              if (this.getIsHeartsPayment) {
                var expiremonth = document.getElementById("expiremonth")
                  .innerHTML;
                var expireyear = document.getElementById("expireyear")
                  .innerHTML;

                if (
                  mytoken == "" ||
                  mytoken == null ||
                  expiremonth == "" ||
                  expiremonth == null ||
                  expireyear == "" ||
                  expireyear == null
                  // this.getPaymentCardCvvNumber == ""
                ) {
                  this.$toastr.e("Card info is required");
                } else {
                  this.SubmitForm();
                }
              } else {
                if (
                  mytoken == "" ||
                  mytoken == null ||
                  this.getPaymentCardHolderName == "" ||
                  this.getPaymentCardExpiry == "" ||
                  this.getPaymentCardCvvNumber == ""
                ) {
                  this.$toastr.e("Card info is required");
                } else {
                  this.SubmitForm();
                }
              }
            }
          } else {
            this.loadingMessage =
              "please wait <br> Your appointment is being scheduled...";

            this.SubmitForm();
          }
        }
      } else {
        if (this.getPaymentType == "C") {
          this.loadingMessage =
            "please wait <br> While we process your payment...";
          if (mytoken == "" || mytoken == null) {
            this.$toastr.e(
              "The card number entered is not compliant, please check and re-enter."
            );
          } else {
            if (this.getIsHeartsPayment) {
              var expiremonth2 = document.getElementById("expiremonth")
                .innerHTML;
              var expireyear2 = document.getElementById("expireyear").innerHTML;

              if (
                mytoken == "" ||
                mytoken == null ||
                expiremonth2 == "" ||
                expiremonth2 == null ||
                expireyear2 == "" ||
                expireyear2 == null
                // this.getPaymentCardCvvNumber == ""
              ) {
                this.$toastr.e("Card info is required");
              } else {
                this.SubmitForm();
              }
            } else {
              if (
                mytoken == "" ||
                mytoken == null ||
                this.getPaymentCardHolderName == "" ||
                this.getPaymentCardExpiry == "" ||
                this.getPaymentCardCvvNumber == ""
              ) {
                this.$toastr.e("Card info is required");
              } else {
                this.SubmitForm();
              }
            }
          }
        } else {
          this.loadingMessage =
            "please wait <br> Your appointment is being scheduled...";
          this.SubmitForm();
        }
      }
    },
    submitForPayment() {
      var mytoken = document.getElementById("mytoken");
      mytoken = mytoken.innerHTML;
      if (this.updateAbbreviationCode != "") {
        if (!this.getAbbreviationCode.success) {
          this.$toastr.e(this.getAbbreviationCode.msg);
        } else {
          if (this.getPaymentType == "C") {
            this.loadingMessage =
              "please wait <br> While we process your payment...";

            if (mytoken == "" || mytoken == null) {
              this.$toastr.e(
                "The card number entered is not compliant, please check and re-enter."
              );
            } else {
              if (this.getIsHeartsPayment) {
                var expiremonth = document.getElementById("expiremonth")
                  .innerHTML;
                var expireyear = document.getElementById("expireyear")
                  .innerHTML;

                if (
                  mytoken == "" ||
                  mytoken == null ||
                  expiremonth == "" ||
                  expiremonth == null ||
                  expireyear == "" ||
                  expireyear == null
                  // this.getPaymentCardCvvNumber == ""
                ) {
                  this.$toastr.e("Card info is required");
                } else {
                  this.SubmitDrugForm();
                }
              } else {
                if (
                  mytoken == "" ||
                  mytoken == null ||
                  this.getPaymentCardHolderName == "" ||
                  this.getPaymentCardExpiry == "" ||
                  this.getPaymentCardCvvNumber == ""
                ) {
                  this.$toastr.e("Card info is required");
                } else {
                  this.SubmitDrugForm();
                }
              }
            }
          } else {
            this.loadingMessage =
              "please wait <br> Your appointment is being scheduled...";

            this.SubmitDrugForm();
          }
        }
      } else {
        if (this.getPaymentType == "C") {
          this.loadingMessage =
            "please wait <br> While we process your payment...";
          if (mytoken == "" || mytoken == null) {
            this.$toastr.e(
              "The card number entered is not compliant, please check and re-enter."
            );
          } else {
            if (this.getIsHeartsPayment) {
              var expiremonth2 = document.getElementById("expiremonth")
                .innerHTML;
              var expireyear2 = document.getElementById("expireyear").innerHTML;

              if (
                mytoken == "" ||
                mytoken == null ||
                expiremonth2 == "" ||
                expiremonth2 == null ||
                expireyear2 == "" ||
                expireyear2 == null
                // this.getPaymentCardCvvNumber == ""
              ) {
                this.$toastr.e("Card info is required");
              } else {
                this.SubmitDrugForm();
              }
            } else {
              if (
                mytoken == "" ||
                mytoken == null ||
                this.getPaymentCardHolderName == "" ||
                this.getPaymentCardExpiry == "" ||
                this.getPaymentCardCvvNumber == ""
              ) {
                this.$toastr.e("Card info is required");
              } else {
                this.SubmitDrugForm();
              }
            }
          }
        } else {
          this.loadingMessage =
            "please wait <br> Your appointment is being scheduled...";
          this.SubmitDrugForm();
        }
      }
    },
    goToNextStep() {
      this.currentStep = this.currentStep + 1;
      this.wizardStep[this.currentStep - 1].current_status = false;
      this.wizardStep[this.currentStep - 1].active_status = true;
      this.wizardStep[this.currentStep].current_status = true;

      this.currentStep = this.currentStep > 5 ? 0 : this.currentStep;
      if (this.currentStep == 0) {
        this.openService(1);
      }
      // this.titleOfNext(this.currentStep);
    },
    storeAppointment(skip = false) {
      this.errorMsg = [];
      this.saveDataLoader = true;
      var formData = {
        appointment: {
          service: this.getServiceId,
          client: this.$route.params.id,
          schedule_date: this.getfrontAppointmentDate
            ? this.$moment(this.getfrontAppointmentDate).format("YYYY-MM-DD")
            : null,
          schedule_time: this.getfrontAppointmentTime
            ? this.getfrontAppointmentTime.start_time
            : null,
          // schedule: this.getfrontAppointmentTime.id,
          abbreviation_code: this.updateAbbreviationCode,
          promocode: this.updatePromoCode.trim(),
          service_ori: this.getServiceOri,
          citizen_type: this.getCitizenTitle,
          qty: parseInt(this.getQty),
          multi_recipient: this.getMultirecipient,
          app_type: this.getAppType,
          archival_period: this.getArchivalPeriodId
        },
        time: {
          start: this.timeFormat(
            this.getfrontAppointmentTime
              ? this.getfrontAppointmentTime.start_time
              : "",
            this.getfrontAppointmentDate
          )
        },
        selected_appointment_id: this.getAppointmentId,
        selected_appointment: this.getApplicants,
        skip: skip
      };
      this.$store
        .dispatch(STORE_APPOINTMENT, {
          url:
            this.getIsDrugTesting == 1
              ? "store/new-first-appointment/drug-testing/"
              : "store/new/appointment/",
          data: formData
        })
        .then(res => {
          this.saveDataLoader = false;
          if (res.code == 200) {
            // this.$toastr.s(res.msg);
            if (this.getIsDrugTesting == 1) {
              this.$store.commit(SET_APPICANTS, res.selected_appointments);
            } else {
              this.$store.dispatch(STORE_APPOINTMENT_ID, {
                selected_appointment_id: res.appointment_id
              });
              this.$store.commit(SET_APPT_TOKEN, res.appt_token);
            }
            if (!skip) {
              this.goToNextStep();
            } else {
              this.currentStep = this.currentStep + 1;
            }
          } else {
            this.$toastr.w("Something went wrong !");
            return;
          }
        })
        .catch(errs => {
          this.saveDataLoader = false;
          this.$toastr.e("Something went wrong !");
          this.errorMsg = errs.msg;
        });
    },
    storeApplicant() {
      this.errorMsg = [];
      this.loadingMessage = "please wait...";
      this.saveDataLoader = true;
      var OtpformData = {
        client_slug: this.$route.params.client_slug,
        phone:
          this.getSelectedCountryPhoneCode.dial_code + this.getApplicantPhoneNo,
        email: this.getApplicantEmail,
        applicant_id: this.getApplicantId,
        app_id: this.getAppointmentId
      };
      this.$store
        .dispatch(OTP_VERIFICATION, {
          url: "send/appointment-otp/",
          data: OtpformData
        })
        .then(res => {
          this.$store.commit(SET_OTP_FOR_SMS_VERIFY, res.data.phone_verified);
          this.$store.commit(SET_OTP_FOR_EMAIL_VERIFY, res.data.email_verified);
          if (
            res.data.phone_verified == false ||
            res.data.email_verified == false
          ) {
            this.openOtpmodal = true;
            this.saveDataLoader = false;
          } else {
            this.storeapp();
          }
        })
        .catch(errs => {
          this.saveDataLoader = false;
          this.$toastr.e("Something went wrong");
          this.errorMsg = errs;
        });
    },
    resetotp() {
      this.otp = {
        otp_phone: "",
        is_phone: false,
        otp_email: "",
        is_email: false
      };
    },
    otpverify() {
      this.errorMsg = [];
      this.loadingMessage = "please wait...";
      this.saveDataLoader = true;
      if (this.otp.otp_phone == "") {
        this.otp.is_phone = true;
        this.saveDataLoader = false;
        return 0;
      }
      if (this.getIsDrugTesting) {
        var OtpformData = {
          phone:
            this.getSelectedCountryPhoneCode.dial_code +
            this.getApplicants[0].applicant.phone_no,
          email: this.getApplicants[0].applicant.email,
          applicant_id: this.getApplicantId,
          app_id: this.getApplicants[0].id,
          otp: this.otp.otp_phone
        };
      } else {
        OtpformData = {
          phone:
            this.getSelectedCountryPhoneCode.dial_code +
            this.getApplicantPhoneNo,
          email: this.getApplicantEmail,
          applicant_id: this.getApplicantId,
          app_id: this.getAppointmentId,
          otp: this.otp.otp_phone
        };
      }
      this.$store
        .dispatch(VERIFY_OTP_SMS, {
          url: "verify/appointment/phone-otp/",
          data: OtpformData
        })
        .then(res => {
          this.$toastr.s(res.msg);
          this.$store.commit(SET_OTP_FOR_SMS_VERIFY, res.data.phone_verified);
          if (this.getIsDrugTesting) {
            this.$store.dispatch(STORE_APPOINTMENT_APPLICANT_INFO_ID, {
              applicant_id: res.data.applicant_id
            });
          } else {
            this.$store.commit(SET_APPLICATION_INFO_ID, res.data.applicant_id);
          }

          if (
            res.data.phone_verified == true &&
            this.getemailverification == false
          ) {
            this.openOtpmodal = true;
            this.saveDataLoader = false;
          } else {
            this.openOtpmodal = false;
            this.resetotp();
            if (this.getIsDrugTesting) {
              this.storeDrugApplicantAfterOtp();
            } else {
              this.storeapp();
            }
          }
        })
        .catch(errs => {
          this.saveDataLoader = false;
          this.$toastr.e(errs.msg);
          this.errorMsg = errs;
        });
    },
    otpemailverify() {
      this.errorMsg = [];
      this.loadingMessage = "please wait...";
      this.saveDataLoader = true;
      if (this.otp.otp_email == "") {
        this.otp.is_email = true;
        this.saveDataLoader = false;
        return 0;
      }
      if (this.getIsDrugTesting) {
        var OtpformData = {
          phone:
            this.getSelectedCountryPhoneCode.dial_code +
            this.getApplicants[0].applicant.phone_no,
          email: this.getApplicants[0].applicant.email,
          applicant_id: this.getApplicantId,
          app_id: this.getApplicants[0].id,
          otp: this.otp.otp_email
        };
      } else {
        OtpformData = {
          phone:
            this.getSelectedCountryPhoneCode.dial_code +
            this.getApplicantPhoneNo,
          email: this.getApplicantEmail,
          applicant_id: this.getApplicantId,
          app_id: this.getAppointmentId,
          otp: this.otp.otp_email
        };
      }
      this.$store
        .dispatch(VERIFY_OTP_EMAIL, {
          url: "verify/appointment/email-otp/",
          data: OtpformData
        })
        .then(res => {
          this.$toastr.s(res.msg);
          this.$store.commit(SET_OTP_FOR_EMAIL_VERIFY, res.data.email_verified);
          if (this.getIsDrugTesting) {
            this.$store.dispatch(STORE_APPOINTMENT_APPLICANT_INFO_ID, {
              applicant_id: res.data.applicant_id
            });
          } else {
            this.$store.commit(SET_APPLICATION_INFO_ID, res.data.applicant_id);
          }
          if (res.data.email_verified == false) {
            this.openOtpmodal = true;
            this.saveDataLoader = false;
          } else {
            this.openOtpmodal = false;
            this.resetotp();
            if (this.getIsDrugTesting) {
              this.storeDrugApplicantAfterOtp();
            } else {
              this.storeapp();
            }
          }
        })
        .catch(errs => {
          this.saveDataLoader = false;
          this.$toastr.e(errs.msg);
          this.errorMsg = errs;
        });
    },
    storeapp() {
      this.errorMsg = [];
      this.saveDataLoader = true;
      var formData = {
        appointment_id: this.getAppointmentId,
        applicant_id: this.getApplicantId,
        form_data: {
          fname: this.getApplicantFirstName,
          mname: this.getApplicantMiddleName,
          lname: this.getApplicantlastName,
          email: this.getApplicantEmail,
          phone_no:
            this.getSelectedCountryPhoneCode.dial_code +
            this.getApplicantPhoneNo,
          address1: this.getApplicantAddressLine1,
          address2: this.getApplicantAddressLine2,
          city: this.getApplicantCity,
          zipcode: this.getApplicantZipCode,
          reason: this.getApplicantReason,
          state: this.getApplicantState,
          ori: this.getApplicantOri
        }
      };
      // console.log(formData);

      this.$store
        .dispatch(STORE_APPOINTMENT_APPLICANT_INFO, {
          url: "store/new/appointment/applicant/",
          data: formData
        })
        .then(res => {
          this.saveDataLoader = false;
          if (res.code == 200) {
            // this.$toastr.s(res.msg);
            this.$store.dispatch(STORE_APPOINTMENT_APPLICANT_INFO_ID, {
              applicant_id: res.applicant_id
            });
            this.$store.commit(SET_APPICANTS, [
              {
                appt_token: res.token,
                id: res.applicant_id
              }
            ]);
            this.goToNextStep();
          } else {
            this.$toastr.w(res.msg);
          }
        })
        .catch(errs => {
          this.saveDataLoader = false;
          this.$toastr.e("Please enter all mandatory fields");
          this.errorMsg = errs;
        });
    },
    storeDrugApplicant() {
      this.errorMsg = [];
      this.loadingMessage = "please wait...";
      let applicant = this.getApplicants;
      this.saveDataLoader = true;
      if (applicant[0].applicant.id != "") {
        this.$store.dispatch(STORE_APPOINTMENT_APPLICANT_INFO_ID, {
          applicant_id: applicant[0].applicant.id
        });
      }
      var OtpformData = {
        client_slug: this.$route.params.client_slug,
        phone:
          this.getSelectedCountryPhoneCode.dial_code +
          applicant[0].applicant.phone_no,
        email: applicant[0].applicant.email,
        applicant_id: this.getApplicantId,
        app_id: applicant[0].id
      };
      this.$store
        .dispatch(OTP_VERIFICATION, {
          url: "send/appointment-otp/",
          data: OtpformData
        })
        .then(res => {
          this.$store.commit(SET_OTP_FOR_SMS_VERIFY, res.data.phone_verified);
          this.$store.commit(SET_OTP_FOR_EMAIL_VERIFY, res.data.email_verified);
          if (
            res.data.phone_verified == false ||
            res.data.email_verified == false
          ) {
            this.openOtpmodal = true;
            this.saveDataLoader = false;
          } else {
            this.storeDrugApplicantAfterOtp();
          }
        })
        .catch(errs => {
          this.saveDataLoader = false;
          this.$toastr.e(errs.msg);
          this.errorMsg = errs;
        });
    },
    storeDrugApplicantAfterOtp() {
      this.errorMsg = [];
      this.saveDataLoader = true;
      let applicant = this.getApplicants;
      if (this.getApplicantId == 0) {
        applicant[0].applicant.id = "";
      } else {
        applicant[0].applicant.id = this.getApplicantId;
      }
      // console.log(applicant[0].applicant.phone_no);
      applicant[0].applicant.dial_code = this.getSelectedCountryPhoneCode.dial_code;
      // console.log(applicant);
      this.$store
        .dispatch(STORE_DRUG_APPOINTMENT_APPLICANT_INFO, {
          data: [...applicant]
        })
        .then(res => {
          this.saveDataLoader = false;
          if (res.code == 200) {
            // this.$toastr.s(res.msg);
            this.$store
              .dispatch(STORE_APPOINTMENT_APPLICANT_INFO_ID, {
                applicant_id: res.applicant_id
              })
              .then(() => {
                this.goToNextStep();
              });
          } else {
            this.$toastr.w(res.msg);
            return;
          }
        })
        .catch(errs => {
          this.saveDataLoader = false;
          this.$toastr.e("Please enter all mandatory fields");
          this.errorMsg = errs;
        });
    },
    storeApplicantPersonalIdentity() {
      this.errorMsg = [];
      this.saveDataLoader = true;
      var formData = {
        appointment_id: this.getAppointmentId,
        applicant_id: this.getApplicantId,
        form_data: {
          ssn: this.getApplicantSSN,
          dob: this.getApplicantDateOfBirth,
          place_of_birth: this.getApplicantPlaceOfBirth,
          country_of_citizenship: this.getApplicantcounrtyOfCitizeb,
          gender: this.getApplicantGender,
          race: this.getApplicantRace,
          eye_color: this.getApplicantEyeColor,
          hair_color: this.getApplicantHairColor,
          height: this.getApplicantHeight,
          weight: this.getApplicantWeight
        }
      };

      this.$store
        .dispatch(STORE_APPLICANT_PERSONAL_INFO, {
          url: "store/new/next/appointment/applicant/",
          data: formData
        })
        .then(res => {
          this.saveDataLoader = false;
          if (res.code == 200) {
            // this.$toastr.s(res.msg);
            this.$store.dispatch(STORE_APPOINTMENT_APPLICANT_INFO_ID, {
              applicant_id: res.applicant_id
            });
            this.goToNextStep();
          }
        })
        .catch(errs => {
          this.saveDataLoader = false;
          this.$toastr.e("Please enter all mandatory fields");
          this.errorMsg = errs;
        });
    },

    SubmitForm() {
      this.errorMsg = [];
      this.saveDataLoader = true;
      let updateAbbreviationCode = "";
      if (this.getAbbreviationCode.data.length == 0) {
        updateAbbreviationCode = "";
      } else if (!this.getAbbreviationCode.success) {
        updateAbbreviationCode = "";
      } else if (this.getAbbreviationCode.success == "none") {
        updateAbbreviationCode = "";
      } else {
        updateAbbreviationCode = this.updateAbbreviationCode;
      }
      if (this.getPromoCode.data.length == 0) {
        this.updatePromoCode = "";
      } else if (!this.getPromoCode.success) {
        this.updatePromoCode = "";
      } else if (this.getPromoCode.success == "none") {
        this.updatePromoCode = "";
      }
      var mytoken = document.getElementById("mytoken");
      var formData = {
        type: this.getPaymentType,
        appointment_id: this.getAppointmentId,
        applicant_id: this.getApplicantId,
        promo_code: this.updatePromoCode.trim(),
        archival_period: this.getArchivalPeriodId,
        abbreviation_code: updateAbbreviationCode,
        sms_status: this.getSmsStatus,
        appt_token: this.getApptToken,

        // applicant: {
        //   fname: this.getApplicantFirstName,
        //   mname: this.getApplicantMiddleName,
        //   lname: this.getApplicantlastName,
        //   email: this.getApplicantEmail,
        //   phone_no:
        //     this.getSelectedCountryPhoneCode.dial_code +
        //     this.getApplicantPhoneNo,
        //   address1: this.getApplicantAddressLine1,
        //   address2: this.getApplicantAddressLine2,
        //   city: this.getApplicantCity,
        //   zipcode: this.getApplicantZipCode,
        //   ssn: this.getApplicantSSN,
        //   dob: this.getApplicantDateOfBirth,
        //   place_of_birth: this.getApplicantPlaceOfBirth,
        //   country_of_citizenship: this.getApplicantcounrtyOfCitizeb,
        //   gender: this.getApplicantGender,
        //   race: this.getApplicantRace,
        //   eye_color: this.getApplicantEyeColor,
        //   hair_color: this.getApplicantHairColor,
        //   height: this.getApplicantHeight,
        //   weight: this.getApplicantWeight
        // },
        // appointment: {
        //   service: this.getServiceId,
        //   client: this.$route.params.id,
        //   schedule_date: this.$moment(this.getfrontAppointmentDate).format(
        //     "YYYY-MM-DD"
        //   ),
        //   schedule: this.getfrontAppointmentTime.id,
        //   reason: this.getApplicantReason,
        //   abbreviation_code: this.updateAbbreviationCode,
        //   citizen_type: this.getCitizenTitle
        // },

        time: {
          start: this.timeFormat(
            this.getfrontAppointmentTime
              ? this.getfrontAppointmentTime.start_time
              : "",
            this.getfrontAppointmentDate
          )
        }
      };
      var invoice_details = [];

      // var rate = parseFloat(this.getfClientRate.rate);
      // var taxAmount = (
      //   (parseFloat(this.getfClientRate.tax_percentage) / 100) *
      //   rate
      // ).toFixed(2);
      // var totalAmount = parseFloat(
      //   parseFloat(rate) + parseFloat(taxAmount)
      // ).toFixed(2);
      var data_1 = {
        qty: this.getfClientRate.service.default_qty,
        // rate: rate,
        // code_: this.getfClientRate.commission_percentage,
        is_additional: 0,
        // tax_amount: taxAmount,
        // total_amount: totalAmount,
        title: "Service Name: " + this.getfClientRate.title
      };
      invoice_details.push(data_1);
      if (this.getAddQty > 0) {
        // rate = parseFloat(this.getfClientRate.additional_rate);
        // taxAmount = (
        //   (parseFloat(this.getfClientRate.tax_percentage) / 100) *
        //   rate *
        //   this.getAddQty
        // ).toFixed(2);
        // totalAmount = parseFloat(
        //   parseFloat(rate * this.getAddQty) + parseFloat(taxAmount)
        // ).toFixed(2);

        var data_2 = {
          qty: this.getAddQty,
          // rate: rate,
          // code_: 0,
          is_additional: 1,
          // tax_amount: taxAmount,
          // total_amount: totalAmount,
          title: "Additional: Yes (" + this.getAddQty + ")"
        };
        invoice_details.push(data_2);
      }
      if (this.getPaymentType == "C") {
        var token = mytoken.innerHTML;
        if (this.getIsHeartsPayment) {
          var expiremonth = document.getElementById("expiremonth");
          var expireyear = document.getElementById("expireyear");
          var token_type = document.getElementById("token_type");
          var last_four = document.getElementById("last_four");
          var card_type = document.getElementById("card_type");
          formData.payment_details = {
            hearts_token: token,
            expire_month: expiremonth.innerHTML,
            expire_year: expireyear.innerHTML,
            // card_cvv_no: this.getPaymentCardCvvNumber,
            postal: this.getPaymentCardPostalZip,
            token_type: token_type.innerHTML,
            last_four: last_four.innerHTML,
            card_type: card_type.innerHTML,
            card_address: this.getPaymentCardHolderAddress
            // card_city: this.getPaymentCardHolderCity,
            // card_state: this.getPaymentCardHolderState
          };
        } else {
          formData.payment_details = {
            card_number: token,
            card_holder_name: this.getPaymentCardHolderName,
            card_expiry: this.getPaymentCardExpiry,
            card_cvv_no: this.getPaymentCardCvvNumber,
            card_type: this.getPaymentCardType,
            // amount: this.toFixedTwoDigit_(
            //   this.getTotalRateAmount,
            //   this.getfClientRate
            // ),
            card_address: this.getPaymentCardHolderAddress,
            card_city: this.getPaymentCardHolderCity,
            card_country: this.getPaymentCardHolderCountry,
            card_state: this.getPaymentCardHolderState,
            phone:
              this.getPaymentCardPhonecode.dial_code + this.getPaymentCardPhone,
            postal: this.getPaymentCardPostalZip
          };
        }
      }
      // if (this.getPaymentType != "C") {
      formData.invoice_details = invoice_details;
      // }

      this.$store
        .dispatch(STORE_APPOINTMENT_FROM_APPLICANT, {
          url:
            this.getPaymentType == "C"
              ? "appointment/applicant/payment"
              : "submit/appointment/applicant/",
          data: formData
        })
        .then(res => {
          if (this.getPaymentType != "C") {
            this.saveDataLoader = false;
            this.$store
              .dispatch(MAKE_INVOICE_AFTER_ONLINE_PAYEMENT, {
                appointment_id: res.appointment_id,
                invoice_details: invoice_details,
                card_type: this.getPaymentCardType,
                card_number: this.getPaymentCardNumber
                  .replace(" ", "")
                  .slice(-4),
                card_token: "",
                appt_token: this.getApptToken,
                invoice_id: res.invoice_id
              })
              .then(() => {
                // this.$toastr.s(data.msg);
                this.saveDataLoader = false;
                this.reset();
              })
              .catch(errs => {
                this.errorMsg = errs.msg;
                this.saveDataLoader = false;
                // this.$toastr.e(errs.detail);
                this.errorMsg = errs.msg;
              });
            this.$toastr.s(res.msg);
            // this.$store.commit(SET_APPOINTMENT_ID, res.appointment_id);
            this.reset();
            this.$router.push({
              name: "client.appointment.view",
              query: { token: res.token, app_id: res.appointment_id }
            });
            // window.location.reload();
          } else {
            this.$store
              .dispatch(MAKE_INVOICE_AFTER_ONLINE_PAYEMENT, {
                appointment_id: res.appointment_id,
                invoice_details: invoice_details,
                card_type: this.getPaymentCardType,
                card_number: this.getPaymentCardNumber
                  .replace(" ", "")
                  .slice(-4),
                // card_token: res.card_token,
                card_token: "",
                appt_token: this.getApptToken,
                invoice_id: res.invoice_id
              })
              .then(() => {
                // this.$toastr.s(data.msg);
                this.saveDataLoader = false;
                this.reset();
              })
              .catch(errs => {
                this.errorMsg = errs.msg;
                this.saveDataLoader = false;
                // this.$toastr.e(errs.detail);
                this.errorMsg = errs.msg;
              });
            this.$toastr.s(res.msg);

            this.reset();
            this.$router.push({
              name: "client.appointment.view",
              query: { token: res.token, app_id: res.appointment_id }
            });
          }
        })
        .catch(errs => {
          this.saveDataLoader = false;
          // this.$toastr.e(errs.msg);
          // console.log(errs)
          this.errorMsg = errs.msg;
        });
    },
    SubmitDrugForm() {
      this.errorMsg = "";
      this.saveDataLoader = true;
      let updateAbbreviationCode = "";
      if (this.getAbbreviationCode.data.length == 0) {
        updateAbbreviationCode = "";
      } else if (!this.getAbbreviationCode.success) {
        updateAbbreviationCode = "";
      } else if (this.getAbbreviationCode.success == "none") {
        updateAbbreviationCode = "";
      } else {
        updateAbbreviationCode = this.updateAbbreviationCode;
      }
      // let updatePromoCode = "";
      if (this.getPromoCode.data.length == 0) {
        this.updatePromoCode = "";
      } else if (!this.getPromoCode.success) {
        this.updatePromoCode = "";
      } else if (this.getPromoCode.success == "none") {
        this.updatePromoCode = "";
      }
      var mytoken = document.getElementById("mytoken");
      var formData = {
        type: this.getPaymentType,
        abbreviation_code: updateAbbreviationCode,
        sms_status: this.getSmsStatus,
        selected_appointment: this.getApplicants,
        app_type: this.getAppType,
        promo_code: this.updatePromoCode.trim(),
        qty: parseInt(this.getQty),
        time: {
          start: this.timeFormat(
            this.getfrontAppointmentTime
              ? this.getfrontAppointmentTime.start_time
              : "",
            this.getfrontAppointmentDate
          )
        }
      };

      if (this.getPaymentType == "C") {
        var token = mytoken.innerHTML;
        if (this.getIsHeartsPayment) {
          var expiremonth = document.getElementById("expiremonth");
          var expireyear = document.getElementById("expireyear");
          var token_type = document.getElementById("token_type");
          var last_four = document.getElementById("last_four");
          var card_type = document.getElementById("card_type");
          formData.payment_details = {
            hearts_token: token,
            expire_month: expiremonth.innerHTML,
            expire_year: expireyear.innerHTML,
            // card_cvv_no: this.getPaymentCardCvvNumber,
            postal: this.getPaymentCardPostalZip,
            token_type: token_type.innerHTML,
            last_four: last_four.innerHTML,
            card_type: card_type.innerHTML,
            card_address: this.getPaymentCardHolderAddress
            // card_city: this.getPaymentCardHolderCity,
            // card_state: this.getPaymentCardHolderState
          };
        } else {
          formData.payment_details = {
            card_number: token,
            card_holder_name: this.getPaymentCardHolderName,
            card_expiry: this.getPaymentCardExpiry,
            card_cvv_no: this.getPaymentCardCvvNumber,
            card_type: this.getPaymentCardType,

            // amount: this.toFixedTwoDigit_(
            //   this.getTotalRateAmount,
            //   this.getfClientRate
            // ),
            card_address: this.getPaymentCardHolderAddress,
            card_city: this.getPaymentCardHolderCity,
            card_country: this.getPaymentCardHolderCountry,
            card_state: this.getPaymentCardHolderState,
            phone:
              this.getPaymentCardPhonecode.dial_code + this.getPaymentCardPhone,
            postal: this.getPaymentCardPostalZip
          };
        }
      }
      // if (this.getPaymentType != "C") {
      // }

      this.$store
        .dispatch(STORE_APPOINTMENT_FROM_APPLICANT, {
          url:
            this.getPaymentType == "C"
              ? "store/drug-testing/submit/appointment/payment/"
              : "store/drug-testing/submit/appointment/",
          data: formData
        })
        .then(res => {
          if (this.getPaymentType != "C") {
            this.saveDataLoader = false;
            this.$store
              .dispatch(SUCCESS_DRUG_TESTING_PAYEMENT, {
                appointment_id: res.appointment_id,
                card_type: this.getPaymentCardType,
                card_number: this.getPaymentCardNumber
                  .replace(" ", "")
                  .slice(-4),
                card_token: "",
                appt_token: res.token,
                invoice_id: res.invoice_id
              })
              .then(() => {
                // this.$toastr.s(data.msg);
                this.saveDataLoader = false;
                this.reset();
              })
              .catch(errs => {
                this.errorMsg = errs.msg;
                this.saveDataLoader = false;
                // this.$toastr.e(errs.detail);
                this.errorMsg = errs.msg;
              });
            this.$toastr.s(res.msg);
            // this.$store.commit(SET_APPOINTMENT_ID, res.appointment_id);
            this.reset();
            this.$router.push({
              name: "client.appointment.view",
              query: { token: res.token, app_id: res.appointment_id }
            });
            // window.location.reload();
          } else {
            this.$store
              .dispatch(SUCCESS_DRUG_TESTING_PAYEMENT, {
                appointment_id: res.appointment_id,
                card_type: this.getPaymentCardType,
                card_number: this.getPaymentCardNumber
                  .replace(" ", "")
                  .slice(-4),
                // card_token: res.card_token,
                card_token: "",
                appt_token: res.token,
                invoice_id: res.invoice_id
              })
              .then(() => {
                // this.$toastr.s(data.msg);
                this.saveDataLoader = false;
                this.reset();
              })
              .catch(errs => {
                this.errorMsg = errs.msg;
                this.saveDataLoader = false;
                // this.$toastr.e(errs.detail);
                this.errorMsg = errs.msg;
              });
            this.$toastr.s(res.msg);

            this.reset();
            this.$router.push({
              name: "client.appointment.view",
              query: { token: res.token, app_id: res.appointment_id }
            });
          }
        })
        .catch(errs => {
          this.saveDataLoader = false;
          // this.$toastr.e(errs.msg);
          // console.log(errs)
          this.errorMsg = errs.msg;
        });
    },

    titleOfNext(currentStep) {
      // console.log("asbfjasP", currentStep);

      switch (currentStep) {
        case 0:
          this.nextBtn = "Next";
          this.nextBtnIcon = "fa-arrow-right";
          break;

        case 1:
          this.nextBtn = "Next";
          this.nextBtnIcon = "fa-arrow-right";
          break;

        case 2:
          this.nextBtn = "Next";
          this.nextBtnIcon = "fa-arrow-right";

          break;

        case 3:
          this.nextBtnIcon = "fa-check";
          this.nextBtn = "Confirm";
          break;

        case 4:
          this.nextBtnIcon = "fa-check";
          this.nextBtn = "Confirm";
          break;

        case 5:
          this.nextBtnIcon = "fa-check";
          this.nextBtn = "Submit";
          break;
      }
    },
    ResendEmailOtp() {
      this.errorMsg = [];
      this.loadingMessage = "please wait...";
      this.saveDataLoader = true;
      // let applicant = this.getApplicants;
      if (this.getIsDrugTesting) {
        var OtpformData = {
          email: this.getApplicants[0].applicant.email,
          app_id: this.getApplicants[0].id,
          client_slug: this.$route.params.client_slug,
          applicant_id: this.getApplicantId
        };
      } else {
        OtpformData = {
          email: this.getApplicantEmail,
          app_id: this.getAppointmentId,
          client_slug: this.$route.params.client_slug
        };
      }
      this.$store
        .dispatch(RESEND_OTP_EMAIL, {
          url: "resend/appointment/email-otp/",
          data: OtpformData
        })
        .then(res => {
          this.$toastr.s(res.msg);
          this.saveDataLoader = false;
        })
        .catch(errs => {
          this.saveDataLoader = false;
          this.$toastr.e(errs.msg);
          this.errorMsg = errs;
        });
    },
    ResendPhoneOtp() {
      this.errorMsg = [];
      this.loadingMessage = "please wait...";
      this.saveDataLoader = true;
      if (this.getIsDrugTesting) {
        var OtpformData = {
          phone:
            this.getSelectedCountryPhoneCode.dial_code +
            this.getApplicants[0].applicant.phone_no,
          app_id: this.getApplicants[0].id,
          client_slug: this.$route.params.client_slug,
          applicant_id: this.getApplicantId
        };
      } else {
        OtpformData = {
          phone:
            this.getSelectedCountryPhoneCode.dial_code +
            this.getApplicantPhoneNo,
          app_id: this.getAppointmentId,
          client_slug: this.$route.params.client_slug
        };
      }
      this.$store
        .dispatch(RESEND_OTP_SMS, {
          url: "resend/appointment/phone-otp/",
          data: OtpformData
        })
        .then(res => {
          this.$toastr.s(res.msg);
          this.saveDataLoader = false;
        })
        .catch(errs => {
          this.saveDataLoader = false;
          this.$toastr.e(errs.msg);
          this.errorMsg = errs;
        });
    },

    back() {
      var client_slug = this.$route.params.client_slug;
      // alert(this.getfServiceSlug);
      if (this.currentStep == 0) {
        var id = this.$route.params.id;
        if (this.getServiceType == "citizen") {
          this.$router.push({
            name: "client.appointment.citizenship",
            params: { id: id, client_slug: client_slug }
          });
        } else if (this.getServiceType == "DISCLOSURE") {
          this.$router.push({
            name: "client.appointment.disclosure",
            params: { id: id, client_slug: client_slug }
          });
        } else if (this.getServiceType == "none") {
          this.$store.commit(SET_APPOINTMENT_ID, 0);
          this.$store.commit(SET_APPICANTS, []);
          this.$store.commit(SET_APPLICATION_INFO_ID, 0);

          this.$router.push({
            name: "frontend.agent.locations",
            params: { slug: this.getfServiceSlug, client_slug: client_slug }
          });
        }
      } else {
        this.wizardStep[this.currentStep].current_status = false;
        this.currentStep = this.currentStep - 1;
        this.wizardStep[this.currentStep].active_status = false;
        this.wizardStep[this.currentStep].current_status = true;
      }
      //  this.wizardStep[this.currentStep - 1].current_status = false;
      //  this.wizardStep[this.currentStep - 1].active_status = true;
      // this.wizardStep[this.currentStep].current_status = true;
      // this.wizardStep[this.currentStep + 1].current_status = true;
      // this.wizardStep[this.currentStep].active_status = false;

      this.currentStep = this.currentStep < 0 ? 0 : this.currentStep;
      if(this.onlyApostilleService){
        if(this.currentStep == 5){
          this.titleOfNext(4);
        } else if(this.currentStep < 4){
            this.titleOfNext(0);
          }
      }else if (!this.getIsDrugTesting) {
        let currentStep = this.currentStep;
        if (currentStep == 3) {
          currentStep = 2;
        }

        // } else {
        this.titleOfNext(currentStep);
        // }
      } else {
        let currentStep = this.currentStep;
        if (currentStep == 5) {
          currentStep = 4;
        } else if (currentStep == 3) {
          currentStep = 2;
        } else if (currentStep == 2) {
          currentStep = 1;
        } else {
          currentStep = this.currentStep;
        }
        this.titleOfNext(currentStep);
      }
    },
    timeFormat(value, date) {
      if (value == "") {
        return "";
      }
      var fullDate = date + " " + value;
      return this.$moment(fullDate).format("hh:mm a");
    },
    //   timeFormat: function(value, date, timezone) {
    //   var fullDate = date + " " + value;
    //   var a = this.$moment.utc(fullDate, 'YYYYMMDD HH:mm:ss');
    //   return a.clone().tz(timezone).format('hh:mm a');
    // },
    openService(id) {
      this.$router.push({ name: "frontend.booknow" });
      this.$router.push({ params: { id: id } });
    },
    toFixedTwoDigit_(amount, data) {
      // console.log(data.service.default_qty);
      var rate = parseFloat(data.rate);
      // var rate = parseFloat(data.rate);
      var tp = parseFloat(data.tax_percentage);
      return (parseFloat(amount) + (rate + (rate * tp) / 100)).toFixed(2);
    },
    validateApostilleData(wizard){
      if(wizard == 0){
        if(
          this.getApplicantFirstName == "" ||
          this.getApplicantlastName == "" ||
          this.getApplicantEmail == "" ||
          this.getApplicantConfirmEmail == "" ||
          this.getApplicantPhoneNo == ""
        ){
          this.$toastr.e("Please enter all mandatory fields ");
        }else{
          if (!this.regEmail.test(this.getApplicantEmail)) {
            this.$toastr.e("Please enter a valid email address");
          }else {
            if (this.getApplicantEmail != this.getApplicantConfirmEmail) {
              this.$toastr.e("Email and confirm email doesn't matched");
            } else {
              if (this.getApplicantPhoneNo.length != 14) {
                this.$toastr.e("Please enter valid phone number");
              } else {
                this.$store.dispatch(FETCH_F_AVAILABLE_COUNTRY, {
                    service_id: this.getServiceId
                });
                return true;
              }
            }
          }
        }
      }
      else if(wizard == 1){
        if(this.getDeliveryAddress.name == "" ||
          this.getDeliveryAddress.company_name == "" ||
          this.getDeliveryAddress.address == "" || 
          this.getDeliveryAddress.city == "" ||
          this.getDeliveryAddress.postal_code == "" ||
          this.getDeliveryAddress.state == "" ||
          this.getDeliveryAddress.country == "" ||
          this.getDeliveryAddress.contact == ""
        ){
          this.$toastr.e("Please enter all mandatory fields ");
        }else{
          if (this.getDeliveryAddress.contact.length != 14) {
            this.$toastr.e("Please enter valid phone number");
          } else {
            return true;
          }
        }
      }else if(wizard == 2){
        if(this.getDocumentCountry == -1){
          this.$toastr.e("Please select a country");
        }else{
          if(this.getDocumentInformationList.length == 0){
            this.$toastr.e("Please upload atleast one document");
          }else{
            return true;
          }  
        }
      }else if(wizard == 3){
        return true;
      }else if(wizard == 4){
        this.loadingMessage = "please wait...";
        this.saveDataLoader = true;
        var formData = {
          service_id: this.getServiceId,
          applicantContact: {
            first_name: this.getApplicantFirstName,
            middle_name: this.getApplicantMiddleName,
            last_name: this.getApplicantlastName,
            email: this.getApplicantEmail,
            confirm_email: this.getApplicantConfirmEmail,
            phone_no: this.getApplicantPhoneNo
          },
          client_id: this.$route.params.id,
          info_documents: this.getDocumentInformationList,
          delivery_address: this.getDeliveryAddress,
          promo_code: this.getPromoCode,
          abbreviation_code: this.getAbbreviationCode,
          doc_translation_rate_status: this.getTranslationRateStatus,
          doc_scan_rate_status: this.getScanRateStatus,
          appointment: this.getApostilleSubmittedData,
          document_country_id: this.getDocumentCountry,
          expedited_rate_status: this.getIsExpeditedRate,
        }
        this.$store.dispatch(SUBMIT_APOSTILLE_APPOINTMENT, formData)
        .then(data => {
          setTimeout(() => {
              this.$store.commit(
                SET_TOTAL_SERVICE_AMOUNT, parseFloat(data.data.total_cost.grand_total).toFixed(2)
              );
              this.$store.commit(SET_APOSTILLE_SUBMITTED_DATA, data.data);
              setTimeout(() => {
                this.currentStep = this.currentStep + 1;
                this.saveDataLoader = false;
              }, 10);
            }, 1);
          
        })
        .catch((e) => {
          this.saveDataLoader = false;
        });
      }else if(wizard == 5){
        var token = document.getElementById("mytoken");
        var token = token.innerHTML;

        var success = this.apostille_payment(token);

        if(success){
          var paymentData ={
            service_id: this.getServiceId,
            client_id: this.$route.params.id,
            paymentType: this.getPaymentType,
            payment_details: this.getPaymentDetails, 
            appointment_id: this.getApostilleSubmittedData.appointment_id,
            applicant_id: this.getApostilleSubmittedData.applicant_id,
            token : this.getApostilleSubmittedData.token,
            saved_docs : this.getApostilleSubmittedData.saved_docs,
            document_country_id: this.getDocumentCountry,
            delivery_details_id : this.getApostilleSubmittedData.delivery_details_id,
            doc_translation_rate_status: this.getTranslationRateStatus,
            doc_scan_rate_status: this.getScanRateStatus,
            promo_details: {
              promo_code_id: this.getPromoCode.data.id,
              promo_code: this.getPromoCode.data.promo_code,
            },
            abbreviation_details: {
              abbreviation_code: this.getAbbreviationCode.data.abbreviation_code,
              abbreviation_code_id: this.getAbbreviationCode.data.id
            },
            is_signature_required: this.getIsSignatureRequired,
            expedited_rate_status: this.getIsExpeditedRate,
          }

          if (this.getPaymentType == "C") {
            if (this.getIsHeartsPayment) {
              var expiremonth = document.getElementById("expiremonth");
              var expireyear = document.getElementById("expireyear");
              var token_type = document.getElementById("token_type");
              var last_four = document.getElementById("last_four");
              var card_type = document.getElementById("card_type");
              paymentData.payment_details = {
                hearts_token: token,
                expire_month: expiremonth.innerHTML,
                expire_year: expireyear.innerHTML,
                // card_cvv_no: this.getPaymentCardCvvNumber,
                postal: this.getPaymentCardPostalZip,
                token_type: token_type.innerHTML,
                last_four: last_four.innerHTML,
                card_type: card_type.innerHTML,
                card_address: this.getPaymentCardHolderAddress
              };
            } else {
              paymentData.payment_details = {
                card_number: token,
                card_holder_name: this.getPaymentCardHolderName,
                card_expiry: this.getPaymentCardExpiry,
                card_cvv_no: this.getPaymentCardCvvNumber,
                card_type: this.getPaymentCardType,
                card_address: this.getPaymentCardHolderAddress,
                card_city: this.getPaymentCardHolderCity,
                card_country: this.getPaymentCardHolderCountry,
                card_state: this.getPaymentCardHolderState,
                phone:
                  this.getPaymentCardPhonecode.dial_code + this.getPaymentCardPhone,
                postal: this.getPaymentCardPostalZip
              };
            }
          }

          this.$store.dispatch(SUBMIT_APOSTILLE_PAYMENT, paymentData)
          .then(data => {
            this.$toastr.s(data.msg);
            this.reset();
            this.$router.push({
              name: "client.appointment.view",
              query: { token: data.data.token, app_id: data.data.appointment_id }
            });
          })
          .catch(errs => {
            var errorMsg = errs.error;
            this.$toastr.e(errorMsg);
          });
        }
        // this.$toastr.s('Last');
      }
    },
    apostille_payment(token){
      if (this.updateAbbreviationCode != "") {
        if (!this.getAbbreviationCode.success) {
          this.$toastr.e(this.getAbbreviationCode.msg);
        } else {
          if (this.getPaymentType == "C") {
            this.loadingMessage =
              "please wait <br> While we process your payment...";

            if (token == "" || token == null) {
              this.$toastr.e(
                "The card number entered is not compliant, please check and re-enter."
              );
            } else {
              if (this.getIsHeartsPayment) {
                var expiremonth = document.getElementById("expiremonth")
                  .innerHTML;
                var expireyear = document.getElementById("expireyear")
                  .innerHTML;

                if (
                  token == "" ||
                  token == null ||
                  expiremonth == "" ||
                  expiremonth == null ||
                  expireyear == "" ||
                  expireyear == null
                  // this.getPaymentCardCvvNumber == ""
                ) {
                  this.$toastr.e("Card info is required");
                } else {
                  return true;
                }
              } else {
                if (
                  token == "" ||
                  token == null ||
                  this.getPaymentCardHolderName == "" ||
                  this.getPaymentCardExpiry == "" ||
                  this.getPaymentCardCvvNumber == ""
                ) {
                  this.$toastr.e("Card info is required");
                } else {
                  return true;
                }
              }
            }
          } else {
            this.loadingMessage =
              "please wait <br> Your appointment is being created...";

            return true;
          }
        }
      } else {
        if (this.getPaymentType == "C") {
          this.loadingMessage =
            "please wait <br> While we process your payment...";
          if (token == "" || token == null) {
            this.$toastr.e(
              "The card number entered is not compliant, please check and re-enter."
            );
          } else {
            if (this.getIsHeartsPayment) {
              var expiremonth2 = document.getElementById("expiremonth")
                .innerHTML;
              var expireyear2 = document.getElementById("expireyear").innerHTML;

              if (
                token == "" ||
                token == null ||
                expiremonth2 == "" ||
                expiremonth2 == null ||
                expireyear2 == "" ||
                expireyear2 == null
                // this.getPaymentCardCvvNumber == ""
              ) {
                this.$toastr.e("Card info is required");
              } else {
                return true;
              }
            } else {
              if (
                token == "" ||
                token == null ||
                this.getPaymentCardHolderName == "" ||
                this.getPaymentCardExpiry == "" ||
                this.getPaymentCardCvvNumber == ""
              ) {
                this.$toastr.e("Card info is required");
              } else {
                return true;
              }
            }
          }
        } else {
          this.loadingMessage =
            "please wait <br> Your appointment is being created...";
            return true;
        }
      }
    },
    fetchRate() {
      this.loadingData = true;
      if (this.updateServiceOriCode != "") {
        this.service_ori = this.updateServiceOriCode;
        this.checkServiceOri();
        this.loadingData = false;
      } else {
        var id = this.$route.params.id;
        var service_id = this.getServiceId;

        this.$store
          .dispatch(FETCH_F_CLIENT_RATE, {
            id: id,
            service_id: service_id
          })
          .then(data => {
            var rate = ((parseFloat(data.rate) * data.service.default_qty) + (parseFloat(data.rate) * data.service.default_qty * data.tax_percentage * 1 / 100)).toFixed(2);
            setTimeout(() => {
              this.$store.commit(
                SET_TOTAL_SERVICE_AMOUNT, rate
              );
            }, 1);
          })
          .catch(() => {
            this.loadingData = false;
          });
      }
    },
  }
};
</script>
