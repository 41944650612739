<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
</style>
<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="row" v-if="AppType">
        <div class="col-md-4">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Search by appointment no or name"
              aria-describedby="basic-addon2"
              v-model="search_text"
              @keypress.enter="search()"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-info"
                type="button"
                @click="search()"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr v-if="AppType" />
      <appointmentDetailModal
        :key="key"
        v-if="appointmentDetailModalShow"
        v-on:closemodal="closeModal"
        v-on:updatepaymentstatus="updatepaymentstatus"
        :loading-data="loadingData"
        :has-data="hasData"
        :show-modal="appointmentDetailModalShow"
        :appointment-data="appointmentData"
        :index-num="currentIndex"
        :show-qr-code="showQrCode"
        :token="token"
        @reLoadTable="reTableReload"
        @reload="search"
      ></appointmentDetailModal>

      <div class="row" v-if="AppType">
        <canvas
          id="barcode"
          class="d-none"
          style="width: inherit; height: 95px"
        ></canvas>

        <div class="col-md-2 pr-0" v-if="!currentUser.u.is_client">
          <div class="form-group">
            <select
              @keypress.enter="appData()"
              v-model="serviceOutlet"
              @change="loadServices()"
              name=""
              class="form-control"
              id=""
            >
              <option value="all">All Location</option>
              <option
                v-for="(citem, index) in getClients"
                :key="index"
                :value="citem.id"
              >
                {{ citem.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-2 pl-2 pr-0">
          <div class="form-group">
            <select
              @keypress.enter="appData()"
              v-model="service"
              name=""
              class="form-control"
              id=""
            >
              <option value="all">All Service</option>
              <option
                v-for="(citem, index) in getServices"
                :key="index"
                :value="citem.id"
              >
                {{ citem.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-2 pl-2 pr-0">
          <div class="form-group">
            <input
              @keypress.enter="appData()"
              type="date"
              v-model="date_from"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-2 pl-2 pr-0">
          <div class="form-group">
            <input
              @keypress.enter="appData()"
              type="date"
              v-model="date_to"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-1 pl-2 pr-0">
          <div class="form-group">
            <select
              @keypress.enter="appData()"
              v-model="status"
              class="form-control"
            >
              <option value="all">All App Status</option>
              <option value="P">Pending</option>
              <option value="C">Completed</option>
              <option value="U">No-Show</option>
              <option value="Cancel">Canceled</option>
              <!-- <option value="Refund">Refunded</option> -->
            </select>
          </div>
        </div>

        <div class="col-md-2 pl-2 pr-0">
          <div class="form-group">
            <select
              @keypress.enter="appData()"
              class="form-control"
              v-model="payment_status"
            >
              <option value="all">All Payment Status</option>
              <option value="C">Card</option>
              <option value="O">On-site</option>
              <option value="A">Abbreviation</option>
              <option value="CP">Company Payment</option>
            </select>
          </div>
        </div>

        <div class="col-md-1">
          <button class="btn btn-outline-info" @click="advSearch()">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>
      <hr v-if="AppType" />
      <div
        class="row"
        v-if="appoinments.length > 0 && !getLoadingData && AppType"
      >
        <div class="col-md-12">
          <!-- <download-excel
            class="btn btn-primary ml-3"
            style="float:right"
            :data="appointments"
            :fields="json_fields"
            :worksheet="date_from + ' - ' + date_to"
            name="Appointment List.xls"
          >
            <i class="test-white fa fa-download"></i> Download Excel
          </download-excel> -->
          <a
            @click="refreshData()"
            href="#"
            class="btn btn-secondary font-weight-bolder"
            style="float: right"
          >
            <i class="text-white fa fa-refresh"></i> Refresh</a
          >
        </div>
      </div>
      <hr v-if="appoinments.length > 0 && !getLoadingData && AppType" />
      <div class="row" v-if="appoinments.length > 0 && !getLoadingData">
        <div class="col-md-12">
          <v-table
            :columns="columns"
            v-if="AppType && DashType == 'appointment_list'"
          >
            <tr v-for="(item, index) in appoinments" :key="index">
              <td>
                <h6
                  style="font-size: 13px; font-weight: 400"
                  v-if="item.schedule_date != null"
                >
                  {{ item.schedule_date | dateFormat }}
                </h6>
                <h6
                  style="font-size: 13px; font-weight: 400"
                  v-if="item.schedule_date != null"
                >
                  {{ item.start_time | timeFormat(item.schedule_date) }}
                </h6>
                <h6
                  style="font-size: 13px; font-weight: 400"
                  v-if="item.schedule_date == null && item.created_at != null"
                >
                {{ item.created_at | dateFormat }}
                </h6>
                <h6
                  style="font-size: 13px; font-weight: 400"
                  v-if="item.schedule_date == null && item.created_at == null"
                >
                  N/A
                </h6>
              </td>
              <td class="">
                <a
                  href="javascript:void;"
                  @click="
                    openAppointmentDetail(item.id, item.token, index, true)
                  "
                >
                  {{ item.id }}</a
                >
                <br />
              </td>
              <td class="">
                <h6 style="font-size: 13px; font-weight: 400">
                  {{ !item.app_type ? "Live Scan" : capitalize(item.app_type) }}
                </h6>
              </td>
              <td>
                <!-- <a
                  href="javascript:void;"
                  @click="
                    openAppointmentDetail(item.id, item.token, index, true)
                  "
                >
                  {{ item.applicant }}</a
                > -->
                {{ item.applicant }}
              </td>

              <td>
                <b>
                  {{ item.service }}
                  <span v-if="item.is_additional"
                    >,{{ item.is_additional }}</span
                  >
                </b>
                <br />
                {{ item.client }}
              </td>

              <td class="text-right">${{ item.amount | toFixedTwoDigit }}</td>
              <td class="text-center">
                {{
                  item.abbreviation_code != "" && item.abbreviation_code != null
                    ? item.abbreviation_code
                    : "-"
                }}
              </td>
              <td>
                <div class="input-group" v-if="item.change_status_flag">
                  <select
                    name=""
                    id=""
                    class="form-control"
                    v-model="item.new_status"
                  >
                    <option value="P" :selected="item.appt_status == 'P'">
                      Pending
                    </option>
                    <option value="C" :selected="item.appt_status == 'C'">
                      Completed
                    </option>
                    <option value="U" :selected="item.appt_status == 'U'">
                      No-Show
                    </option>
                    <option
                      value="Cancel"
                      :selected="item.appt_status == 'Cancel'"
                    >
                      Cancel
                    </option>
                  </select>
                  <div class="input-group-append">
                    <label
                      v-if="item.appt_status != item.new_status"
                      class="input-group-text"
                      style="cursor: pointer"
                      @click="
                        updateStatusChangesFlag(item.id, index, item.new_status)
                      "
                      for="inputGroupSelect02"
                      ><i class="fa fa-check text-success"></i
                    ></label>
                    <label
                      class="input-group-text"
                      style="cursor: pointer"
                      @click="closeChangeStatusFlag(index)"
                      for="inputGroupSelect02"
                      ><i class="fa fa-times text-danger"></i
                    ></label>
                  </div>
                </div>
                <span
                  v-if="!item.change_status_flag"
                  style="cursor: pointer"
                  @click="changeStatusOpen(item.id, index)"
                  class="badge text-white"
                  :class="' badge-' + statusColor(item.appt_status)"
                  >{{ item.appt_status | apptStatus }}</span
                >
              </td>
              <td>
                <span
                  class="text-white badge badge-primary"
                  style="cursor: pointer"
                  @click="
                    openAppointmentDetail(item.id, item.token, index, false)
                  "
                >
                  {{ item.payment_type | payementStatus }}
                </span>
              </td>
              <td class="text-center">
                <i
                  class="text-success fa fa-check"
                  v-if="item.payment_status == 'P'"
                ></i>
                <i
                  class="text-danger fa fa-times"
                  v-if="item.payment_status == 'U'"
                ></i>
                <span
                  v-if="item.payment_status == 'Refund'"
                  style="cursor: pointer"
                  class="badge text-white badge-danger"
                >
                  Refunded</span
                >
              </td>
              <td class="pr-0 text-right" style="width: 120px">
                <a
                  v-if="
                    item.payment_status == 'P' && item.appt_status != 'Cancel'
                  "
                  href="javascript:void()"
                  @click="openInvoice(item.token)"
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  >Invoice
                  <i class="fa fa-arrow-right ml-1"></i>
                </a>
              </td>
            </tr>
          </v-table>

          <v-table :columns="columns" v-else-if="DashType == 'today'">
            <tr v-for="(item, index) in appoinments" :key="index">
              <td class="text-right">
                <a
                  href="javascript:void;"
                  @click="
                    openAppointmentDetail(item.id, item.token, index, false)
                  "
                >
                  {{ item.id }}</a
                >
              </td>
              <td>
                {{ item.applicant }}
              </td>

              <td>
                <b>
                  {{ item.service }}
                </b>
                <br />
                {{ item.client }}
              </td>
            </tr>
          </v-table>

          <v-table :columns="columns" v-else>
            <tr v-for="(item, index) in appoinments" :key="index">
              <td>
                <h6 style="font-size: 12px">
                  {{ item.schedule_date | dateFormat }}
                </h6>
                <h6 style="font-size: 12px">
                  {{ item.start_time | timeFormat(item.schedule_date) }}
                </h6>
              </td>
              <td class="text-right">
                {{ item.id }}
              </td>
              <td>
                {{ item.applicant }}
              </td>

              <td>
                <b>
                  {{ item.service }}
                </b>
                <br />
                {{ item.client }}
              </td>
            </tr>
          </v-table>
        </div>
      </div>
      <div class="row" v-if="appoinments.length < 1 && !getLoadingData">
        <div class="col-md-12 text-center">
          <h4>no appointment(s) found</h4>
        </div>
      </div>

      <div class="row" v-if="getLoadingData">
        <div class="col-md-12 text-center">
          <v-data-loader></v-data-loader>
        </div>
      </div>
      <hr v-if="pagination.total_page > 1" />
      <vl-pagination
        v-on:selected="selectedPage"
        v-on:previous="previousPage"
        v-on:first="firstPage"
        v-on:last="lastPage"
        v-on:next="nextPage"
        v-if="pagination.total_page > 1"
        :pagination="pagination"
      ></vl-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  FETCH_CLIENT_APPOINTMENT,
  FETCH_CLIENTS,
  FETCH_SERVICES,
  UPDATE_APPOINTMENT_STATUS,
  FETCH_SELF_APPOINMENT,
  APPOINTMENT_LOGS
} from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import vTable from "@/includes/table/Datatable";
import vlPagination from "@/includes/table/Pagination";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
import * as PDF417 from "pdf417-generator";
import appointmentDetailModal from "@/components/frontend/modal/appointmentdetail";
// import JsonExcel from "vue-json-excel";
export default {
  components: {
    vTable,
    vlPagination,
    vDataLoader,
    appointmentDetailModal
    // downloadExcel: JsonExcel
  },
  computed: {
    ...mapGetters(["currentUser", "getClients", "getServices"])
  },
  props: {
    AppType: {
      type: Boolean,
      default: true
    },
    DashType: {
      type: String,
      default: "appointment_list"
    }
  },
  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    },
    timeFormat: function(value, date) {
      if (date == null) {
        return;
      }
      var fullDate = date + " " + value;
      var a = moment(fullDate).format("hh:mm a");
      return a;
    },
    dateFormat(date) {
      if (date == null) {
        return;
      }
      return moment(date).format("MM/DD/YYYY");
    },
    payementStatus(status) {
      if (status == "C") {
        return "Card";
      }
      if (status == "A") {
        return "Abbreviation";
      }
      if (status == "O") {
        return "On-site";
      }
      if (status == "CP") {
        return "Company-payment";
      }
      if (status == "Cancel") {
        return "Cancel";
      }
    },

    apptStatus(status) {
      var s = "";
      switch (status) {
        case "P":
          s = "Pending";
          break;

        case "C":
          s = "Completed";
          break;

        case "U":
          s = "No-Show";
          break;
        case "Cancel":
          s = "Canceled";
          break;
      }
      return s;
    }
  },
  data() {
    return {
      appointmentDetailModalShow: false,
      json_fields: {
        Date: "schedule_date",
        "Appointment No": "id",
        Applicant: "applicant",
        "Service Outlet": "sp_name",
        Service: "service",
        "Start Time": "start_time"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ],
      showQrCode: false,
      getLoadingData: false,
      columns: [
        {
          name: "date",
          width: "",
          label: "Date",
          class: ""
        },
        {
          name: "App_no",
          width: "5%",
          label: "App No.",
          class: ""
        },
        {
          name: "App_type",
          width: "",
          label: "Capture Type",
          class: ""
        },
        {
          name: "applicant",
          width: "",
          label: "Applicant",
          class: ""
        },
        {
          name: "Service/location",
          width: "",
          label: "Service/Location",
          class: ""
        },

        {
          name: "amount",
          width: "",
          label: "Amount",
          class: "text-right"
        },
        {
          name: "abbr_code",
          width: "",
          label: "Abbr.Code",
          class: "text-center"
        },
        {
          name: "status",
          width: "",
          label: "Status",
          class: ""
        },
        {
          name: "payment",
          width: "",
          label: "Payment",
          class: ""
        },
        {
          name: "Paid",
          width: "",
          label: "Paid",
          class: "text-center"
        },
        {
          name: "action",
          width: "",
          label: "",
          class: ""
        }
      ],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Appointment List",
          route: ""
        }
      ],
      service: "all",
      serviceOutlet: "all",
      date_from: this.$moment(new Date()).format("YYYY-MM-DD"),
      date_to: this.$moment(new Date())
        .add(7, "days")
        .format("YYYY-MM-DD"),
      status: "all",
      payment_status: "all",
      appoinments: [],
      offset: 0,
      limit: 20,
      pagination: {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      },
      selectedAppId: 0,
      appointmentData: {},
      currentIndex: -1,
      loadingData: false,
      hasData: false,
      msg: "",
      search_text: "",
      token: "",
      key: 0
    };
  },

  mounted() {
    if (window.localStorage.getItem("appoitmentsearch") != null) {
      let search = JSON.parse(window.localStorage.getItem("appoitmentsearch"));
      this.status = search.status;
      this.service = search.service;
      this.serviceOutlet = search.service_outlet
        ? search.service_outlet
        : "all";
      this.date_from = search.date_from
        ? search.date_from
        : this.$moment(new Date()).format("YYYY-MM-DD");
      this.date_to = search.date_to
        ? search.date_to
        : this.$moment(new Date())
            .add(7, "days")
            .format("YYYY-MM-DD");
      this.type = search.currentUser;
      this.payment_status = search.payment_status;
      this.$store.dispatch(FETCH_SERVICES, this.serviceOutlet);
    } else {
      this.serviceOutlet = this.currentUser.u.is_client ? "self" : "all";
      this.$store.dispatch(FETCH_SERVICES, this.serviceOutlet);
    }
    this.$store.dispatch(FETCH_CLIENTS);
    if (this.AppType) {
      this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    }
    this.appData();
    if (!this.AppType) {
      if (this.DashType == "today") {
        this.columns = [
          {
            name: "App_no",
            width: "10%",
            label: "App No.",
            class: ""
          },
          {
            name: "applicant",
            width: "",
            label: "Applicant",
            class: ""
          },
          {
            name: "Service/location",
            width: "",
            label: "Service/Location",
            class: ""
          }
        ];
      } else {
        this.columns = [
          {
            name: "date",
            width: "",
            label: "Date",
            class: ""
          },
          {
            name: "App_no",
            width: "10%",
            label: "App No.",
            class: ""
          },
          {
            name: "applicant",
            width: "",
            label: "Applicant",
            class: ""
          },
          {
            name: "Service/location",
            width: "",
            label: "Service/Location",
            class: ""
          }
        ];
      }
    }
  },

  methods: {
    search() {
      this.pagination = {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      };
      this.appData();
    },
    reTableReload(index) {
      this.appoinments[index].payment_status = "Refund";
    },
    advSearch() {
      this.search_text = "";
      this.search();
    },
    openInvoice(token) {
      // alert(token);
      this.$router.push({
        name: "client.invoice.details",
        params: { slug: this.$route.params.client_slug, token: token }
      });
    },
    updatepaymentstatus(i, ps, is_drug_testing) {
      if (is_drug_testing) {
        this.refreshData();
      }
      this.appoinments[i].payment_status = ps;
    },
    closeModal() {
      this.appointmentDetailModalShow = false;
    },
    openAppointmentDetail(appId, token, index, showQrCode) {
      let now = moment(new Date());
      let appointment_date = moment(
        this.appoinments[index].schedule_date +
          " " +
          this.appoinments[index].start_time
      );
      if (this.appoinments[index].cancel == 1 && this.currentUser.u.is_client) {
        return 0;
      }

      if (
        (now.diff(appointment_date, "hours") >= 24 &&
          this.currentUser.u.is_client) ||
        (this.currentUser.u.is_client && this.appoinments[index].is_locked == 1)
      ) {
        return 0;
      }
      this.showQrCode = showQrCode;
      this.currentIndex = index;
      this.appointmentDetailModalShow = true;
      this.selectedAppId = appId;
      this.loadingData = true;
      var data = {
        token: token,
        appointment_id: appId
      };

      this.$store
        .dispatch(FETCH_SELF_APPOINMENT, data)
        .then(res => {
          this.appointmentData = res.data;
          this.token = token;

          this.loadingData = false;
          this.hasData = res.hasData;
          this.key += 1;

          if (showQrCode) {
            var DBB = this.$moment(res.data.dob).format("MMDDYYYY");
            var DBC = res.data.gender == "Female" ? 2 : 1;
            var DAU = ((res.data.height * 12) / 100).toFixed(2);
            var value =
              "@\n\nANSI 636020090102DL00410279ZC03200010DLDAQ171774992\nDCA\nDCB\nDCD\nDBA\nDCS" +
              res.data.lname +
              "\nDAC" +
              res.data.fname +
              "\nDAD" +
              res.data.mname +
              "\nDBD\nDBB" +
              DBB +
              "\nDBC" +
              DBC +
              "\nDAY" +
              res.data.eye_color +
              "\nDAU0" +
              DAU * 100 +
              " in\nDAG" +
              "\nDAI" +
              res.data.city +
              "\nDAJ" +
              res.data.address +
              "\nDAK" +
              res.data.zipcode +
              "\nDAQ\nDCF\nDCG\nDDE\nDDF\nDDG";
            var canvas = document.getElementById("barcode");
            PDF417.draw(value, canvas);
            res.data.barcode_base64 = canvas.toDataURL("image/png");
          }

          this.appointmentData = res.data;
          this.$store.dispatch(APPOINTMENT_LOGS, {
            app_id: appId
          });
        })
        .catch(() => {
          this.loadingData = false;
        });
    },
    refreshData() {
      this.pagination = {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      };
      this.appData();
    },
    closeChangeStatusFlag(index) {
      this.selectedAppId = 0;
      this.appoinments[index].change_status_flag = false;
    },
    storeStatusChange(appId, appt_status, index, status) {
      // console.log(status);

      this.$store
        .dispatch(UPDATE_APPOINTMENT_STATUS, {
          app_id: appId,
          appt_status: appt_status
        })
        .then(res => {
          this.$toastr.s(res.msg);
          this.appoinments[index].appt_status = status;
          if (status == "Cancel") {
            this.appoinments[index].cancel = 1;
            // this.appoinments[index].payment_status = "U";
          } else {
            this.appoinments[index].cancel = 0;
            // this.appoinments[index].payment_status = "P";
          }

          this.closeChangeStatusFlag(index);
        })
        .catch(err => {
          this.$toastr.e(err.msg);
        });
    },
    updateStatusChangesFlag(appId, index, appt_status) {
      var status = this.appoinments[index].new_status;
      if (status != this.appoinments[index].appt_status) {
        if (status == "Cancel") {
          let message = `You want to cancel the appointment no. ${this.appoinments[index].id}`;
          this.$swal
            .fire({
              title: "Are you sure?",
              text: message,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
              cancelButtonText: "No"
            })
            .then(result => {
              if (result.isConfirmed) {
                this.storeStatusChange(appId, appt_status, index, status);
              } else {
                this.closeChangeStatusFlag(index);
              }
            });
        } else {
          this.storeStatusChange(appId, appt_status, index, status);
        }
      } else {
        this.appoinments[index].appt_status = status;
        this.closeChangeStatusFlag(index);
      }
    },
    changeStatusOpen(appointMentId, index) {
      // console.log(this.appoinments[index]);
      if (
        this.appoinments[index].cancel == 0 ||
        this.currentUser.u.is_service_provider
      ) {
        this.selectedAppId = appointMentId;
        this.appoinments[index].change_status_flag = true;
      }
    },
    statusColor(status) {
      if (status == "P") {
        return "warning";
      }
      if (status == "C") {
        return "success";
      }
      if (status == "U") {
        return "info";
      }
      if (status == "Cancel") {
        return "danger";
      }
    },
    selectedPage(n) {
      this.pagination.current_page = n;
      this.appData();
    },
    nextPage() {
      this.pagination.current_page = this.pagination.current_page + 1;
      this.appData();
    },
    lastPage() {
      this.pagination.current_page = this.pagination.total_page;
      this.appData();
    },
    firstPage() {
      this.pagination.current_page = 1;
      this.appData();
    },
    previousPage() {
      this.pagination.current_page = this.pagination.current_page - 1;
      this.appData();
    },
    loadServices() {
      this.service = "all";
      this.$store.dispatch(FETCH_SERVICES, this.serviceOutlet);
    },
    capitalize(value) {
      return value.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
    },
    appData() {
      this.appoinments = [];
      this.getLoadingData = true;
      var data = {
        service: this.service,
        service_outlet: this.serviceOutlet,
        date_from: this.date_from,
        date_to: this.date_to,
        type: this.currentUser.u.is_admin
          ? "admin"
          : this.currentUser.u.is_client
          ? "c"
          : "sp",
        status: this.status,
        payment_status: this.payment_status,
        page: this.pagination.current_page,
        search_text: this.search_text,
        dtype: "appointment_list"
      };
      window.localStorage.setItem("appoitmentsearch", JSON.stringify(data));
      if (this.DashType == "today") {
        data.dtype = "today";
      } else if (this.DashType == "upcomming") {
        data.dtype = "upcomming";
      }
      this.$store
        .dispatch(FETCH_CLIENT_APPOINTMENT, data)
        .then(res => {
          this.getLoadingData = false;
          this.appoinments = res.data;
          this.pagination.total_records = res.total_record;
          this.pagination.total_page = res.page_range;
          this.pagination.received_per_page_data = res.data.length;
          this.pagination.per_page_data =
            this.DashType == "appointment_list" ? 15 : 8;

          // console.log(res);
        })
        .catch(err => {
          this.getLoadingData = false;
          this.msg = err.error;
        });
    }
  }
};
</script>
