<template>
    <div class="row">
        <div class="col-lg-12">
            <h5 class="mb-5">Document Information</h5>
            <p>Please list only the specific documents to be Apostilled/Authenticated</p>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 mb-2 d-flex">
                    <label for="" style="font-weight: 600; font-size: 14px; width: 400px; margin-top: auto; margin-bottom: auto;"><span>Country document will be used in:</span> </label>
                    <select type="text" class="form-control" :value="getDocumentCountry" @input="updateDeliverCountry">
                        <option :value="-1">Please Select</option>
                        <option v-for="country in getDocumentCountriesList" 
                        :value="country.id">{{ country.country_name }}</option>
                    </select>
                </div>
            </div>
            <div class="row" v-if="getDocumentCountry != -1">
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="main-container">
                        <div class="container-header">
                            <p class="font-lg-bolder">Upload file</p>
                        </div>
                        <div class="upload-container">
                            <div class="upload-wrapper">
                                <input type="file" id="upload_file" style="display: none;" @change="previewThumbnail" />

                                <img src="@/assets/images/apostille/attach-icon.png" height="50"
                                    @click="performClick('upload_file')" class="cursor-pointer">
                                <div class="d-flex-col">
                                    <p class="font-md-bolder m-0">Upload your files,&nbsp; <span
                                            class="custom-link cursor-pointer"
                                            @click="performClick('upload_file')">Browse</span></p>
                                    <p class="font-muted-sm">Maximun file size is 4 mb</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-12 mt-md-0 mt-2">
                    <div class="main-container">
                        <div class="container-header">
                            <p class="font-lg-bolder">Uploaded file <span class="font-muted-sm">(Click on thumbnail to
                                    preview.)</span></p>
                        </div>
                        <div class="uploaded-container">
                            <div v-show="getDocumentInformationList.length < 1" class="text-center">
                                Please upload a document inorder to preview
                            </div>
                            <div v-show="!(getDocumentInformationList.length < 1)" class="uploaded-items-wrapper" v-for="(file, index) in getDocumentInformationList"
                                v-if="file.dataUrl">
                                <div class="uploaded-items-start">
                                    <!-- Img -->
                                    <div v-if="file.fileType == 'image'">
                                        <img :src="file.dataUrl" :id="'uploaded_image' + index"
                                            :alt="'patient_capture_image' + index" width="80" height="80"
                                            class="cursor-pointer" @click="openImageInNewTab(file)">
                                    </div>
                                    <div v-if="file.fileType == 'pdf'">
                                        <img src="@/assets/images/apostille/pdf-preview.png" :id="'uploaded_image' + index"
                                            :alt="'patient_capture_image' + index" width="80" height="80"
                                            class="cursor-pointer" @click="openImageInNewTab(file)">
                                    </div>
                                    <div>
                                        <p class="m-0 font-lg-bolder">{{ file.fileName }}</p>
                                        <div class="d-flex-row">
                                            <p class="m-0 font-muted-sm">{{ file.fileType }}</p>
                                            <p class="m-0 font-muted-sm">{{ file.fileSize }} &nbsp; {{ file.fileSize ? 'MB' :
                                                ''}}</p>

                                        </div>
                                    </div>
                                </div>
                                <div class="remove-image" @click="removeImage(index)">
                                    <i class="fa fa-times" style="color:  #909192;"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
    SET_DOCUMENT_LIST,
    SET_DOCUMENT_COUNTRY
} from "@/core/services/store/mutations.type";
export default {
    data() {
        return {
            uploaded_images: [],
            countries: [],
        }
    },

    computed: {
        ...mapGetters([
            "getDocumentInformationList",
            "getServiceId",
            "getDocumentCountry",
            "getDocumentCountriesList",
        ]),
    },
    mounted(){
        const countriesList = this.getDocumentCountriesList; 
        const country = countriesList.find(item => item.country_code === 'US');
        if (country && this.getDocumentCountry == -1) {
            this.setCountry(country.id);
        }
        this.uploaded_images = this.getDocumentInformationList;
    },

    methods: {

        /**
         * Simulates a click event on a specified HTML element.
         * @param {string} elemId 
         */
        performClick(elemId) {
            let passedElement = document.getElementById(elemId);

            if (passedElement && document.createEvent) {
                let mouseEvent = document.createEvent("MouseEvents");
                mouseEvent.initEvent("click", true, false);
                passedElement.dispatchEvent(mouseEvent);
            }
        },

        /**
         * Handles file selection for previewing thumbnails of images or PDFs and stores in state.
         * Validates the file type and size before processing the file.
         * 
         * @param {Event} event - The input file change event triggered by the user.
         */
        previewThumbnail(event) {

            let that = this;
            const file = event.target.files[0];

            if (!file) return;

            const reader = new FileReader();
            const fileType = file.type;

            if (this.uploaded_images.length == 1) {
                that.$toastr.e("You can only upload one files!");
                return;
            }

            if (!(fileType == 'application/pdf')) {
                that.$toastr.e("Only PDF can be uploaded!");
                return;
            }

            const MAX_FILE_SIZE = 4 * 1024 * 1024; // 4MB in bytes
            let fileSize = (file.size / (1024 * 1024)).toFixed(2);

            if (file.size > MAX_FILE_SIZE) {
                that.$toastr.e("File size exceeds the 4MB limit!");
                return;
            }

            reader.onload = function (e) {
                var base64DataUrl = e.target.result;
                var selectedFile = {
                    dataUrl: base64DataUrl,
                    fileType: fileType == "application/pdf" ? "pdf" : "unsupported",
                    fileName: file.name,
                    fileSize: fileSize,
                    pageCount: that.getPdfPageCount(atob(base64DataUrl.split(',')[1])),
                }

                that.uploaded_images.push(selectedFile);

            }
            reader.readAsDataURL(file);

            that.$store.commit(SET_DOCUMENT_LIST, that.uploaded_images);

        },
        getPdfPageCount(pdfBinaryString) {
            var pageRegex = /\/Type[\s]*\/Page[^s]/g;
            var matches = pdfBinaryString.match(pageRegex);
            return (matches ? matches.length : 0);
        },

        /**
         * Remove uploaded image from the list
         * @param {int} index 
         */
        removeImage(index) {
            let that = this;
            this.uploaded_images.splice(index, 1);
            that.$store.commit(SET_DOCUMENT_LIST, that.uploaded_images);
        },

        /**
         * Sends image data to the parent component when asked for
         */
        getImages() {
            return this.uploaded_images;
        },

        /**
         * Preview the selected file in new tab currently supports only image and pdf
         * @param file 
         */
        openImageInNewTab(file) {

            const newWindow = window.open("", "_blank");

            if (newWindow) {
                if (file.fileType == 'pdf') {
                    newWindow.document.write(`
                        <!DOCTYPE html>
                        <html>
                        <head>
                            <title>PDF Preview</title>
                            <style>
                                body { margin: 0; display: flex; justify-content: center; align-items: center; height: 100vh; }
                                iframe { width: 100%; height: 100%; border: none; }
                            </style>
                        </head>
                        <body>
                            <iframe src="${file.dataUrl}" frameborder="0"></iframe>
                        </body>
                        </html>
                    `);
                }

                newWindow.document.close();
            }
        },
        updateDeliverCountry(e) {
            this.setCountry(e.target.value);
        },
        setCountry(val){
            this.$store.commit(SET_DOCUMENT_COUNTRY, val);
        }
    }

}
</script>

<style scoped>
.flex-align-center-gap-8 {
    display: flex;
    gap: 8px;
    align-items: center;
    flex-wrap: wrap;
}

.selected-image {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    position: relative;
    height: 125px;
    width: 120px;
    border: 1px solid lightskyblue;
    border-radius: 4px !important;
}

.remove-image {
    cursor: pointer;
    padding: 2px 6px;
    right: 2px;
    top: 2px;
}

.remove-image:hover {
    color: black;
    background: rgba(255, 0, 0, 0.329);
    border-radius: 2px !important;
}

.main-container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 4px !important;
    padding: 8px;
    border: 1px solid lightgray;
    background: white;
}

.container-header {
    border-bottom: 1px solid lightgray;
}

.upload-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border: 2px dashed #cfd2f9;
    margin: 4px;
    border-radius: 8px !important;
    /* cursor: pointer; */
}

.d-flex-col {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.d-flex-row {
    display: flex;
    align-items: center;
    gap: 4px;
}

.custom-link {
    color: #5262ed;
    text-decoration: underline;

}

.font-md-bolder {
    font-size: 14px;
    font-weight: 600;
}

.font-lg-bolder {
    font-size: 15px;
    font-weight: 700;
}

.font-muted-sm {
    font-size: 12px;
    font-weight: 400;
    color: #909192;
}

.m-0 {
    margin: 0px;
}

.uploaded-container {
    display: flex;
    flex-direction: column;
    gap: 6px;

}

.uploaded-items-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid lightgray;
    align-items: center;
}

.uploaded-items-start {
    display: flex;
    gap: 14px;
    justify-content: center;
    align-items: center;
}

.cursor-pointer {
    cursor: pointer;
}
</style>