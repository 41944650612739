<template>
  <div class="row">
    <div class="col-md-12" style="background: white">
      <div class="card form-body" style="border:none">
        <div class="card-body">
          <legend>
            <h6>Services | {{ client.name }}</h6>
          </legend>

          <span v-if="serviceError" class="text-danger">{{
            serviceError
            }}</span>
          <hr />
          <table class="table table-bordered">
            <thead>
              <tr>
                <th colspan="2">
                  <!-- <input
                    type="text"
                    name=""
                    class="form-control table-form-control float-left"
                    placeholder="Search Service"
                    id=""
                  /> -->
                </th>
                <th colspan="5" class="text-center">Default</th>
                <th colspan="5" class="text-center">Custom</th>
              </tr>
              <tr>
                <th class="text-left">
                  <input type="checkbox" name="" id="" v-model="selectAll" @change="selectAllServices()" />
                </th>
                <th class="text-left">Service</th>
                <th class="text-right">Rate</th>
                <th class="text-right">Expedited Rate</th>
                <th class="text-right">Add.Rate</th>
                <th class="text-right">Tax(%)</th>
                <th class="text-right">Commission($)</th>
                <th class="text-right">Rate</th>
                <th class="text-right">Expedited Rate</th>
                <th class="text-right">Add.Rate</th>
                <th class="text-right">Tax(%)</th>
                <th class="text-right">Commission($)</th>
                <th class="text-right">Hide in LiveScan</th>
              </tr>
            </thead>
            <tbody v-if="!loadingData && table_datas.length > 0">
              <tr v-for="(data, index) in table_datas" :key="index">
                <td class="text-left">
                  <input type="checkbox" name="" id="" v-model="data.checked" />
                </td>
                <td class="text-left">{{ data.service }}</td>

                <td class="text-right mt-2" v-if="data.service_code != 'fdle'">
                  {{ data.default_rate }}
                </td>
                <td class="text-right mt-2" v-else></td>
                <td class="text-right mt-2" v-if="data.service_code == 'apostille'">
                  {{ data.default_expedited_rate }}
                </td>
                <td class="text-right mt-2" v-else></td>
                <td class="text-right mt-2" v-if="data.service_code != 'fdle'">
                  {{ data.default_additional_rate }}
                </td>
                <td class="text-right mt-2" v-else></td>
                <td class="text-right mt-2" v-if="data.service_code != 'fdle'">
                  {{ data.default_tax }}
                </td>
                <td class="text-right mt-2" v-else></td>
                <td class="text-right mt-2" v-if="data.service_code != 'fdle'">
                  {{ data.default_commission }}
                </td>
                <td class="text-right mt-2" v-else></td>
                <td v-if="data.service_code != 'fdle'">
                  <input type="number" min="0" name="" class="form-control table-form-control" id=""
                    v-model="data.rate" />
                </td>
                <td class="text-right mt-2" v-else></td>
                <td class="flex-center" v-if="data.service_code == 'apostille'">
                  <div class="input-group mb-3" v-if="data.service_code == 'apostille'">
                    <input type="text" class="form-control" aria-label="Text input with checkbox"
                      v-model="data.expedited_rate" />
                  </div>
                </td>
                <td class="text-right mt-2" v-else></td>
                <td :class="data.has_additional_rate ? 'flex-center' : ''" v-if="data.service_code != 'fdle'">
                  <div class="input-group mb-3" v-if="
                    data.has_additional_rate || data.service_code == 'fdle'
                  ">
                    <input type="text" class="form-control" aria-label="Text input with checkbox"
                      v-model="data.additional_rate" />
                  </div>
                </td>
                <td class="text-right mt-2" v-else></td>
                <td v-if="data.service_code != 'fdle'">
                  <input type="number" min="0" name="" class="form-control table-form-control" id=""
                    v-model="data.tax_percentage" />
                </td>
                <td class="text-right mt-2" v-else></td>
                <td v-if="data.service_code != 'fdle'">
                  <input type="number" min="0" name="" class="form-control table-form-control" id=""
                    v-model="data.commission_percentage" />
                </td>
                <td class="text-right mt-2" v-else></td>
                <td class="text-left">
                  <input type="checkbox" name="" id="" v-model="data.hide_in_livescan" />
                </td>
              </tr>
            </tbody>
            <tbody v-if="loadingData">
              <tr>
                <th class="text-center" colspan="10">
                  <vDataLoader></vDataLoader>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <button class="btn btn-success ml-3" style="float: right" @click="update('S', 'kt_save_service')"
            ref="kt_save_service">
            Update
          </button>

          <button ref="kt_save_add_another_company" class="btn btn-danger mr-3" @click="goBack()" style="float: right">
            <i class="fa fa-arrow-left"></i> Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vDataLoader from "@/components/frontend/spinner.vue";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_B_SERVICES,
  FETCH_B_LOCATION_RATE_LISTS,
  UPDATE_B_LOCATION_RATE_LISTS
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
export default {
  components: {
    vDataLoader
  },
  data() {
    return {
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Locations",
          route: "client.location"
        },
        {
          id: 3,
          title: "Update Rate ",
          route: ""
        }
      ],
      table_datas: [],
      errors: [],
      loadingData: false,
      serviceError: "",
      selectAll: false,
      services: [],
      rates: [],
      client: []
    };
  },
  computed: {
    ...mapGetters(["getBServices", "getBRateLists"])
  },
  // watch: {
  //   services: {
  //     handler(val) {
  //       val.data.map(service => {
  //         // console.log(rate);
  //         // var key = this.getBServices.
  //         var key = this.rates.findIndex(x => x.service.id === service.id);
  //         if (key === -1) {
  //           this.table_datas.push({
  //             id: service.id,
  //             service: service.name,
  //             rate: "",
  //             has_additional_rate: service.has_additional_rate,
  //             additional_rate: "",
  //             tax_percentage: "",
  //             commission_percentage: "",
  //             checked: false,
  //             default_rate: service.default_rate,
  //             default_additional_rate: service.additional_rate,
  //             default_tax: service.tax_percentage,
  //             default_commission: service.commission_percentage
  //           });
  //         } else {
  //           this.table_datas.push({
  //             id: service.id,
  //             service: service.name,
  //             rate: this.rates[key].rate,
  //             has_additional_rate: this.rates[key].has_additional_rate,
  //             additional_rate: this.rates[key].additional_rate,
  //             tax_percentage: this.rates[key].tax_percentage,
  //             commission_percentage: this.rates[key].commission_percentage,
  //             checked: true,
  //             default_rate: service.default_rate,
  //             default_additional_rate: service.additional_rate,
  //             default_tax: service.tax_percentage,
  //             default_commission: service.commission_percentage
  //           });
  //         }
  //       });
  //     }
  //   }
  //   // getBRateLists: {
  //   //   handler(val) {
  //   //     this.table_datas = [];
  //   //     val.map((rate) => {
  //   //       this.table_datas.push({
  //   //         id: rate.service.id,
  //   //         service: rate.service.name,
  //   //         rate: rate.rate,
  //   //         has_additional_rate: rate.has_additional_rate,
  //   //         additional_rate: rate.additional_rate,
  //   //         tax_percentage: rate.tax_percentage,
  //   //         commission_percentage: rate.commission_percentage,
  //   //         checked: true,
  //   //         default_rate: rate.service.default_rate,
  //   //         default_additional_rate: rate.service.additional_rate,
  //   //         default_tax: rate.service.tax_percentage,
  //   //         default_commission: rate.service.commission_percentage,
  //   //       });
  //   //     });
  //   //     let table_datas_ids = [];
  //   //     this.table_datas.map((t) => {
  //   //       table_datas_ids.push(t.id);
  //   //     });
  //   //     this.getBServices.data.map((service) => {

  //   //       console.log(this.table_datas,service);

  //   //       // if (this.table_datas.length > 0) {

  //   //       //   if (!table_datas_ids.includes(service.id)) {
  //   //       //     this.table_datas.push({
  //   //       //       id: service.id,
  //   //       //       service: service.name,
  //   //       //       rate: "",
  //   //       //       has_additional_rate: service.has_additional_rate,
  //   //       //       additional_rate: "",
  //   //       //       tax_percentage: "",
  //   //       //       commission_percentage: "",
  //   //       //       checked: service.checked,
  //   //       //       default_rate: service.default_rate,
  //   //       //       default_additional_rate: service.additional_rate,
  //   //       //       default_tax: service.tax_percentage,
  //   //       //       default_commission: service.commission_percentage,
  //   //       //     });
  //   //       //   }
  //   //       // } else {
  //   //       //    alert(table_datas_ids.includes(service.id));
  //   //       //   this.table_datas.push({
  //   //       //     id: service.id,
  //   //       //     service: service.name,
  //   //       //     rate: "",
  //   //       //     has_additional_rate: false,
  //   //       //     additional_rate: "",
  //   //       //     tax_percentage: "",
  //   //       //     commission_percentage: "",
  //   //       //     checked: false,
  //   //       //     default_rate: service.default_rate,
  //   //       //     default_additional_rate: service.additional_rate,
  //   //       //     default_tax: service.tax_percentage,
  //   //       //     default_commission: service.commission_percentage,
  //   //       //   });
  //   //       // }
  //   //     });
  //   //   },
  //   // },
  // },
  created() {
    this.breadCrumbs[2].title = "Edit Rate";
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    // this.getLocationRateLists();
    this.getServiceData();
  },
  methods: {
    goBack() {
      var client_slug = this.$route.params.client_slug;
      this.$router.push({
        name: "client.location",
        params: { client_slug: client_slug }
      });
    },
    selectAllServices() {
      if (this.selectAll) {
        this.table_datas.filter(service => {
          return (service.checked = true);
        });
      } else {
        this.table_datas.filter(service => {
          return (service.checked = false);
        });
      }
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    getLocationRateLists() {
      this.loadingData = true;
    },
    getServiceData() {
      this.loadingData = true;
      this.$store
        .dispatch(FETCH_B_SERVICES, { status: true })
        .then(data_service => {
          this.$store
            .dispatch(FETCH_B_LOCATION_RATE_LISTS, this.$route.params.id)
            .then(data => {
              this.rates = data.rates;
              this.client = data.client;
              data_service.data.map(service => {
                // console.log(rate);
                // var key = this.getBServices.
                var key = this.rates.findIndex(
                  x => x.service.id === service.id
                );
                if (key === -1) {
                  this.table_datas.push({
                    id: service.id,
                    service: service.name,
                    rate: "",
                    has_additional_rate: service.has_additional_rate,
                    additional_rate: "",
                    tax_percentage: "",
                    commission_percentage: "",
                    checked: false,
                    default_rate: service.default_rate,
                    default_additional_rate: service.additional_rate,
                    default_tax: service.tax_percentage,
                    default_commission: service.commission_percentage,
                    hide_in_livescan: 0,
                    service_code: service.service_type_code,
                    default_expedited_rate: service.default_expedited_rate,
                    expedited_rate: ""
                  });
                } else {
                  this.table_datas.push({
                    id: service.id,
                    service: service.name,
                    rate: this.rates[key].rate,
                    has_additional_rate: this.rates[key].has_additional_rate,
                    additional_rate: this.rates[key].additional_rate,
                    tax_percentage: this.rates[key].tax_percentage,
                    commission_percentage: this.rates[key]
                      .commission_percentage,
                    checked: true,
                    default_rate: service.default_rate,
                    default_additional_rate: service.additional_rate,
                    default_tax: service.tax_percentage,
                    default_commission: service.commission_percentage,
                    hide_in_livescan: this.rates[key].hide_in_livescan,
                    service_code: service.service_type_code,
                    default_expedited_rate: service.default_expedited_rate,
                    expedited_rate: this.rates[key].expedited_rate,
                  });
                }
              });
              this.loadingData = false;
            })
            .catch(() => {
              this.loadingData = false;
            });
        })
        .catch(() => {
          this.loadingData = false;
        });
    },
    update(saveType, ref) {
      var table_datas_ = this.table_datas.filter(
        service => service.checked == true
      );
      if (table_datas_.length == 0) {
        this.$toastr.e("Please select at least one service");
      } else {
        this.errors = [];
        this.loadingButton(ref);
        let _data = {};
        _data.location_id = this.$route.params.id;
        _data.rate_list = table_datas_;

        this.$store
          .dispatch(UPDATE_B_LOCATION_RATE_LISTS, _data)
          .then(data => {
            this.$toastr.s(data.msg);
            this.closeBtnLoad(ref);
            if (saveType === "S") {
              this.$router.push({ name: "client.location" });
            }
          })
          .catch(err => {
            this.errors = err;
            this.selectAll = false;
            this.serviceError = "";
            this.getServiceData();
            this.getLocationRateLists();
            this.closeBtnLoad(ref);
          });
      }
    }
  }
};
</script>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
