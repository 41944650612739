import ApiService from "@/core/services/api.service";
// import router from "../../../../router";
import * as VueGoogleMaps from "vue2-google-maps"; // Import package
import Vue from "vue";
// import JwtService from "@/core/services/jwt.service";

import {
  FETCH_CITIES,
  FETCH_STATES,
  FETCH_COUNTRIES,
  FETCH_RACES,
  FETCH_EYECOLORS,
  FETCH_HAIRCOLORS,
  FETCH_PlACE_OF_BIRTH,
  FETCH_CLIENTS,
  FETCH_SERVICES,
  FETCH_SERVICES_CLIENTS,
  FETCH_ARCHIVALS,
  FETCH_SERVICE_CODES,
  FETCH_SERVICE_PROVIDER_DATA,
  RECAPTCHATOKENVERIFY,
  FETCH_ORI_LIST,
  FETCH_CLIENT_APIKEYS,
  FETCH_F_MAILING_RATE,
  SUBMIT_APOSTILLE_APPOINTMENT,
  SUBMIT_APOSTILLE_PAYMENT,
  FETCH_F_AVAILABLE_COUNTRY,
  FETCH_F_COUNTRY_SHIPPING_RATE,
  SEND_APOSTILLE_EMAIL
} from "../actions.type";
import {
  SET_CITIES,
  SET_STATES,
  SET_COUNTRIES,
  SET_RACES,
  SET_EYECOLORS,
  SET_HAIRCOLORS,
  SET_PlACE_OF_BIRTH,
  SET_ERROR,
  UPDATE_CITY,
  UPDATE_REASONFORFINGERPRINT,
  UPDATE_ZIPCODE,
  UPDATE_ADDRESSLINE2,
  UPDATE_ADDRESSLINE1,
  UPDATE_PHONENO,
  UPDATE_CONFIRMEMAIL,
  UPDATE_EMAIL,
  UPDATE_LASTNAME,
  UPDATE_ORI,
  UPDATE_MIDDLENAME,
  UPDATE_FIRSTNAME,
  UPDATE_STATE,
  UPDATE_WEIGHT,
  UPDATE_HEIGHT,
  UPDATE_HAIRCOLOR,
  UPDATE_EYECOLOR,
  UPDATE_RACE,
  UPDATE_GENDER,
  UPDATE_COUNTRY,
  UPDATE_PLACE_OF_BIRTH,
  UPDATE_DATEOFBIRTH,
  UPDATE_SOCIAL_SECURITY,
  SET_CLIENTS,
  SET_CLIENTS_SP,
  SET_SERVICES,
  SET_SERVICE_CODES,
  UPDATE_PAYMENT_TYPE,
  SET_SERVICE_TYPE,
  SET_CITIZEN_TITLE,
  SET_SELECTED_TIME,
  SET_TIME_ZONE,
  SET_SMS_STATUS,
  GET_SERVICE_PROVIDER_DATA,
  SET_PAYMENT_CARD_NUMBER,
  SET_PAYMENT_CARD_HOLDER_NAME,
  SET_PAYMENT_CARD_ADDRESS,
  SET_PAYMENT_CARD_EXPIRY_DATE,
  SET_PAYMENT_CARD_CVV_NUMBER,
  SET_PAYMENT_CARD_TYPE,
  SET_PAYMENT_CARD_PROPERTY,
  SET_COUNTRY_PHONE_CODE,
  CARD_STATE,
  CARD_COUNTRY,
  SET_PAYMENT_CARD_CITY,
  UPDATE_DATEOFBIRTH_MONTH,
  UPDATE_DATEOFBIRTH_DAY,
  UPDATE_DATEOFBIRTH_YEAR,
  SET_PAYMENT_CARD_NUMBER_PHONE,
  SET_PAYMENT_CARD_NUMBER_POSTAL,
  SET_PAYMENT_CARD_NUMBER_PHONE_CODE,
  APPLICANTDETAIL,
  RECAPTCHATOKEN,
  SET_ERROR_STATUS,
  SET_SERVICE_ORI_LIST,
  SET_ARCHIVALS,
  SET_APIKEYS,
  SET_DOC_TRANSLATION_RATE_STATUS,
  SET_DOC_SCAN_RATE_STATUS,
  SET_DOCUMENT_LIST,
  SET_DOCUMENT_COUNTRY,
  SET_DOCUMENT_COUNTRY_LIST,
  SET_DELIVERY_ADDRESS_NAME,
  SET_DELIVERY_ADDRESS_COMPANY_NAME,
  SET_DELIVERY_ADDRESS_ADDRESS,
  SET_DELIVERY_ADDRESS_CITY,
  SET_DELIVERY_ADDRESS_POSTAL_CODE,
  SET_DELIVERY_ADDRESS_STATE,
  SET_DELIVERY_ADDRESS_COUNTRY,
  SET_DELIVERY_ADDRESS_CONTACT,
  SET_DELIVERY_ADDRESS_PHONE_CODE,
  SET_IS_SELECTED_EXPEDITED_RATE,
  SET_APOSTILLE_SUBMITTED_DATA,
  SET_IS_SIGNATURE_REQUIRED
} from "../mutations.type";

const state = {
  service_ori: "",
  timezone: "",
  archivals: [],
  cities: [],
  countries: [],
  races: [],
  states: [],
  oriList: [],
  services_codes: [],
  hairColors: [],
  eyeColors: [],
  placeOfBirth: [],
  smsStatus: false,
  errors: [],
  logsapikeys: [],
  applicant_detail: {},
  applicantContact: {
    city: null,
    state: null,
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    confirm_email: "",
    phone_no: "",
    address_line_1: "",
    address_line_2: "",
    zip_code: "",
    reason: "",
    ori: "",
  },
  applicantPersonal: {
    social_security_no: "",
    date_of_birth: "",
    place_of_birth: null,
    country_of_citizenship: "United States",
    gender: "",
    race: null,
    eye_color: null,
    hair_color: null,
    height: "",
    weight: "",
    dob_m: "",
    dob_d: "",
    dob_y: "",
  },
  payment_details: {
    card_number: "",
    card_holder_name: "",
    card_expiry: "",
    card_cvv_no: "",
    card_type: "",
    card_address: "",
    card_city: "",
    card_country: "US",
    card_state: "",
    phone: "",
    postal: "",
    // eslint-disable max-len
    phone_code: {
      name: "United States",
      dial_code: "+1",
      code: "US",
      flag:
        // eslint-disable-next-line max-len
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAIWSURBVHja1JY/SBthGIefu1xqS6K20KFDy0kopUiHmphIByUZotRAIZOTWZzFpYtbB0uh6KJTIdQhi9pBSwmmCOpgoUSKFItTh4AU6tCr16Rn5P58XZocDrlYuAz9wfHAcbzv9/2+932/k4QQdFIyHVbHE0iAAlwFgj7HNoG6AoRzudc/A4F/28yL2l7bb269yd9QgJAsS8zMjFIufyWRuHspXqtbnsHrH8oAIQlQJyfzlaGhCNFohJ2dI1Kp/iZ3d49IJvsvvJckmJ197JlACIEsy30KgGUJBgcjFIufSacfsLnpza2tL/x4+qx15fR0Uz84hL8HjG1blEqHJJP9bGx8IpMZ8CSAMIzWq1cUhO24CSzLYWTkPisrH8lm46yuenN9fZ+br156WmRZFgQLjR3YrK2VyWSiFAp7TEw88iTAyZNca4t6e6h/P3EbzTRtxscfks9vk83G27JaPcOuVls/v6o4pltlajo9L1KpebG8vC9isbm2jMXmRDsZhiEAVWn4NTU1ysJCkenpMRYXS55cWnrPcSThUUVhzrquNEeFOjz8vOI4CrXa+aU7+d3p29YJusMYwQD3Drb7AFRd14Xf0nXdtehbfAxdkhG13/5M0HCImiTcPhC2BVIAHMefOWrbCNxYqqZpvlukaVrTIrNye4CK1JH7xpSAXuAOcN3n4KfAceNG62qch4+ygHPpv/+r+DMAXV79BpyNnBoAAAAASUVORK5CYII=",
    },
  },
  paymentType: "C",
  clients: [],
  sp_clients: [],
  services: [],
  service_type: false,
  selectedTime: "",
  selectedCitizenTitle: "",
  serviceProviderData: [],
  paymentCardProperty: {
    cvv_placeholder: "Enter Cvv Number",
    cvv_mask: "####",
  },
  // eslint-disable max-len
  selectedCountryPhoneCode: {
    name: "United States",
    dial_code: "+1",
    code: "US",
    flag:
      // eslint-disable-next-line max-len
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAIWSURBVHja1JY/SBthGIefu1xqS6K20KFDy0kopUiHmphIByUZotRAIZOTWZzFpYtbB0uh6KJTIdQhi9pBSwmmCOpgoUSKFItTh4AU6tCr16Rn5P58XZocDrlYuAz9wfHAcbzv9/2+932/k4QQdFIyHVbHE0iAAlwFgj7HNoG6AoRzudc/A4F/28yL2l7bb269yd9QgJAsS8zMjFIufyWRuHspXqtbnsHrH8oAIQlQJyfzlaGhCNFohJ2dI1Kp/iZ3d49IJvsvvJckmJ197JlACIEsy30KgGUJBgcjFIufSacfsLnpza2tL/x4+qx15fR0Uz84hL8HjG1blEqHJJP9bGx8IpMZ8CSAMIzWq1cUhO24CSzLYWTkPisrH8lm46yuenN9fZ+br156WmRZFgQLjR3YrK2VyWSiFAp7TEw88iTAyZNca4t6e6h/P3EbzTRtxscfks9vk83G27JaPcOuVls/v6o4pltlajo9L1KpebG8vC9isbm2jMXmRDsZhiEAVWn4NTU1ysJCkenpMRYXS55cWnrPcSThUUVhzrquNEeFOjz8vOI4CrXa+aU7+d3p29YJusMYwQD3Drb7AFRd14Xf0nXdtehbfAxdkhG13/5M0HCImiTcPhC2BVIAHMefOWrbCNxYqqZpvlukaVrTIrNye4CK1JH7xpSAXuAOcN3n4KfAceNG62qch4+ygHPpv/+r+DMAXV79BpyNnBoAAAAASUVORK5CYII=",
  },
  recaptcha_token: "",
  error_status: false,
  doc_translation_rate_status: false,
  doc_scan_rate_status: false,
  info_documents: [],
  document_country_id: -1, 
  document_countries_list: [],
  selected_mailer: {
    id: 0,
    type: ''
  },
  delivery_address: {
    name: '',
    company_name: '',
    address: '',
    city: '',
    postal_code: '',
    state: '',
    country: 'US',
    contact: '',
    phone_code: {
      name: "United States",
      dial_code: "+1",
      code: "US",
      flag:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAIWSURBVHja1JY/SBthGIefu1xqS6K20KFDy0kopUiHmphIByUZotRAIZOTWZzFpYtbB0uh6KJTIdQhi9pBSwmmCOpgoUSKFItTh4AU6tCr16Rn5P58XZocDrlYuAz9wfHAcbzv9/2+932/k4QQdFIyHVbHE0iAAlwFgj7HNoG6AoRzudc/A4F/28yL2l7bb269yd9QgJAsS8zMjFIufyWRuHspXqtbnsHrH8oAIQlQJyfzlaGhCNFohJ2dI1Kp/iZ3d49IJvsvvJckmJ197JlACIEsy30KgGUJBgcjFIufSacfsLnpza2tL/x4+qx15fR0Uz84hL8HjG1blEqHJJP9bGx8IpMZ8CSAMIzWq1cUhO24CSzLYWTkPisrH8lm46yuenN9fZ+br156WmRZFgQLjR3YrK2VyWSiFAp7TEw88iTAyZNca4t6e6h/P3EbzTRtxscfks9vk83G27JaPcOuVls/v6o4pltlajo9L1KpebG8vC9isbm2jMXmRDsZhiEAVWn4NTU1ysJCkenpMRYXS55cWnrPcSThUUVhzrquNEeFOjz8vOI4CrXa+aU7+d3p29YJusMYwQD3Drb7AFRd14Xf0nXdtehbfAxdkhG13/5M0HCImiTcPhC2BVIAHMefOWrbCNxYqqZpvlukaVrTIrNye4CK1JH7xpSAXuAOcN3n4KfAceNG62qch4+ygHPpv/+r+DMAXV79BpyNnBoAAAAASUVORK5CYII=",
    },
  },
  is_expedited_rate: false,
  apostille_submitted_data: {},
  is_signature_required: false,
  // uatpayment: {}
};
const getters = {
  getErrorsStatus() {
    return state.error_status;
  },
  getLogsApiKeys() {
    return state.logsapikeys;
  },
  getRecaptchaToken() {
    return state.recaptcha_token;
  },
  getSelectedCountryPhoneCode(state) {
    return state.selectedCountryPhoneCode;
  },
  getapplicantdetail(state) {
    return state.applicant_detail;
  },
  getPaymentDetails(state){
    return state.payment_details;
  },
  getPaymentCardPhone(state) {
    return state.payment_details.phone;
  },
  getPaymentCardPhonecode(state) {
    return state.payment_details.phone_code;
  },
  getPaymentCardPostalZip(state) {
    return state.payment_details.postal;
  },
  getPaymentCardProperty(state) {
    return state.paymentCardProperty;
  },
  getPaymentCardNumber(state) {
    return state.payment_details.card_number;
  },

  getPaymentCardHolderName(state) {
    return state.payment_details.card_holder_name;
  },
  getPaymentCardHolderAddress(state) {
    return state.payment_details.card_address;
  },
  getPaymentCardHolderCity(state) {
    return state.payment_details.card_city;
  },
  getPaymentCardHolderCountry(state) {
    return state.payment_details.card_country;
  },
  getPaymentCardHolderState(state) {
    return state.payment_details.card_state;
  },
  getPaymentCardExpiry(state) {
    return state.payment_details.card_expiry;
  },

  getPaymentCardCvvNumber(state) {
    return state.payment_details.card_cvv_no;
  },
  getPaymentCardType(state) {
    return state.payment_details.card_type;
  },

  getServiceProviderData(state) {
    return state.serviceProviderData;
  },
  getSmsStatus(state) {
    return state.smsStatus;
  },

  getTimeZone(state) {
    return state.timezone;
  },
  getSelectedTime(state) {
    return state.selectedTime;
  },
  getCitizenTitle(state) {
    return state.selectedCitizenTitle;
  },
  getServiceType(state) {
    return state.service_type;
  },
  getCities(state) {
    return state.cities;
  },
  getServiceOri(state) {
    return state.service_ori;
  },
  getCountries(state) {
    return state.countries;
  },
  getStates(state) {
    return state.states;
  },
  getRaces(state) {
    return state.races;
  },
  getEyeColors(state) {
    return state.eyeColors;
  },
  getHairColors(state) {
    return state.hairColors;
  },
  getPlaceOfBirth(state) {
    return state.placeOfBirth;
  },
  getArchivals(state) {
    return state.archivals;
  },

  //applicant contact information getter
  getApplicantCity(state) {
    return state.applicantContact.city;
  },
  getApplicantState(state) {
    return state.applicantContact.state;
  },
  getApplicantFirstName(state) {
    return state.applicantContact.first_name;
  },
  getApplicantMiddleName(state) {
    return state.applicantContact.middle_name;
  },
  getApplicantlastName(state) {
    return state.applicantContact.last_name;
  },
  getApplicantEmail(state) {
    return state.applicantContact.email;
  },
  getApplicantOri(state) {
    return state.applicantContact.ori;
  },
  getApplicantConfirmEmail(state) {
    return state.applicantContact.confirm_email;
  },
  getApplicantPhoneNo(state) {
    return state.applicantContact.phone_no;
  },
  getApplicantAddressLine1(state) {
    return state.applicantContact.address_line_1;
  },
  getApplicantAddressLine2(state) {
    return state.applicantContact.address_line_2;
  },
  getApplicantZipCode(state) {
    return state.applicantContact.zip_code;
  },
  getApplicantReason(state) {
    return state.applicantContact.reason;
  },

  //apostille Service
  getTranslationRateStatus(state) {
    return state.doc_translation_rate_status;
  },
  getScanRateStatus(state) {
    return state.doc_scan_rate_status;
  },
  getSelectedMailer(state){
    return state.selected_mailer;
  },
  getDocumentInformationList(state){
    return state.info_documents;
  },
  getDocumentCountry(state){
    return state.document_country_id;
  },
  getDocumentCountriesList(state){
    return state.document_countries_list;
  },
  //Delivery Address
  getDeliveryAddress(state){
    return state.delivery_address;
  },

  //applicant personal information
  getApplicantSSN(state) {
    return state.applicantPersonal.social_security_no;
  },
  getApplicantDateOfBirth(state) {
    return state.applicantPersonal.date_of_birth;
  },
  getApplicantDobMonth(state) {
    return state.applicantPersonal.dob_m;
  },
  getApplicantDobDay(state) {
    return state.applicantPersonal.dob_d;
  },
  getApplicantDobYear(state) {
    return state.applicantPersonal.dob_y;
  },
  getApplicantPlaceOfBirth(state) {
    return state.applicantPersonal.place_of_birth;
  },
  getApplicantcounrtyOfCitizeb(state) {
    return state.applicantPersonal.country_of_citizenship;
  },
  getApplicantGender(state) {
    return state.applicantPersonal.gender;
  },
  getApplicantRace(state) {
    return state.applicantPersonal.race;
  },
  getApplicantEyeColor(state) {
    return state.applicantPersonal.eye_color;
  },
  getApplicantHairColor(state) {
    return state.applicantPersonal.hair_color;
  },
  getApplicantHeight(state) {
    return state.applicantPersonal.height;
  },
  getApplicantWeight(state) {
    return state.applicantPersonal.weight;
  },
  getClients(state) {
    return state.clients;
  },
  getSpClients(state){
    return state.sp_clients;
  },
  getServices(state) {
    return state.services;
  },
  getPaymentType() {
    return state.paymentType;
  },
  getServiceCodes(state) {
    return state.services_codes;
  },
  getIsExpeditedRate(state){
    return state.is_expedited_rate;
  },
  getApostilleSubmittedData(state){
    return state.apostille_submitted_data;
  },
  getIsSignatureRequired(state){
    return state.is_signature_required;
  }
};
const actions = {
  [FETCH_SERVICE_PROVIDER_DATA](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        "get/" + data.fetch_slug + "/" + data.client_slug,
        data.reset_token
      )
        .then(({ data }) => {
          resolve(data.data);
          Vue.use(VueGoogleMaps, {
            load: {
              key: data.data.key,
              libraries: "places",
            },
          });
          context.commit(GET_SERVICE_PROVIDER_DATA, data.data);
          context.commit(SET_ERROR_STATUS, false);
        })
        .catch(({ err }) => {
          // console.log(err);
          reject(err);
          // router.push({ path: "/page-not-found" });
          context.commit(SET_ERROR, err);
          context.commit(SET_ERROR_STATUS, true);
        });
    });
  },
  [FETCH_CITIES](context) {
    ApiService.get("f/cities")
      .then(({ data }) => {
        context.commit(SET_CITIES, data.data);
      })
      .catch(({ err }) => {
        context.commit(SET_ERROR, err);
      });
  },
  [FETCH_ORI_LIST](context) {
    ApiService.get("fetch/serviceori")
      .then(({ data }) => {
        context.commit(SET_SERVICE_ORI_LIST, data.data);
      })
      .catch(({ err }) => {
        context.commit(SET_ERROR, err);
      });
  },

  [FETCH_STATES](context) {
    ApiService.get("f/states")
      .then(({ data }) => {
        context.commit(SET_STATES, data.data);
      })
      .catch(({ err }) => {
        context.commit(SET_ERROR, err);
      });
  },

  [FETCH_COUNTRIES](context) {
    ApiService.get("f/countries")
      .then(({ data }) => {
        context.commit(SET_COUNTRIES, data.data);
      })
      .catch(({ err }) => {
        context.commit(SET_ERROR, err);
      });
  },

  [FETCH_RACES](context) {
    ApiService.get("f/races")
      .then(({ data }) => {
        context.commit(SET_RACES, data.data);
      })
      .catch(({ err }) => {
        context.commit(SET_ERROR, err);
      });
  },

  [FETCH_EYECOLORS](context) {
    ApiService.get("f/eye_colors")
      .then(({ data }) => {
        context.commit(SET_EYECOLORS, data.data);
      })
      .catch(({ err }) => {
        context.commit(SET_ERROR, err);
      });
  },

  [FETCH_HAIRCOLORS](context) {
    ApiService.get("f/hair_colors")
      .then(({ data }) => {
        context.commit(SET_HAIRCOLORS, data.data);
      })
      .catch(({ err }) => {
        context.commit(SET_ERROR, err);
      });
  },
  [FETCH_PlACE_OF_BIRTH](context) {
    ApiService.get("f/palce_of_birth")
      .then(({ data }) => {
        context.commit(SET_PlACE_OF_BIRTH, data.data);
      })
      .catch(({ err }) => {
        context.commit(SET_ERROR, err);
      });
  },
  [FETCH_SERVICE_CODES](context) {
    ApiService.get("get/service-codes")
      .then(({ data }) => {
        context.commit(SET_SERVICE_CODES, data.data);
      })
      .catch(({ err }) => {
        context.commit(SET_ERROR, err);
      });
  },
  [FETCH_CLIENTS](context,sp_id) {
    ApiService.get("clients",'',{params:{sp_id:sp_id}})
      .then(({ data }) => {
        context.commit(SET_CLIENTS, data.data);
      })
      .catch(({ err }) => {
        context.commit(SET_ERROR, err);
      });
  },
  [FETCH_SERVICES](context, value) {
    if(value.sp){
    ApiService.get("services", value.location,{params:{sp:value.sp}})

      .then(({ data }) => {
        context.commit(SET_SERVICES, data.data);
      })
      .catch(({ err }) => {
        context.commit(SET_ERROR, err);
      });
    }
    else{
      ApiService.get("services", value)

      .then(({ data }) => {
        context.commit(SET_SERVICES, data.data);
      })
      .catch(({ err }) => {
        context.commit(SET_ERROR, err);
      });

    }
  },
  [FETCH_SERVICES_CLIENTS](context, sp_id) {
    ApiService.get("clients", '',{params:{sp_id:sp_id}})

      .then(({ data }) => {
        context.commit(SET_CLIENTS_SP, data.data);
      })
      .catch(({ err }) => {
        context.commit(SET_ERROR, err);
      });
  },
  [FETCH_ARCHIVALS](context, value) {
    ApiService.get("archivals", value)

      .then(({ data }) => {
        context.commit(SET_ARCHIVALS, data.data);
      })
      .catch(({ err }) => {
        context.commit(SET_ERROR, err);
      });
  },
  [RECAPTCHATOKENVERIFY](context, value) {
    return new Promise((resolve, reject) => {
      ApiService.post("verify-recaptcha/", {
        token: value,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ err }) => {
          reject(err);
        });
    });
  },
  [FETCH_CLIENT_APIKEYS](context, value) {
    return new Promise((resolve, reject) => {
      ApiService.post("get/apikeys/list/", {
        slug: value,
      })
        .then(({ data }) => {
          context.commit(SET_APIKEYS, data.data);
          resolve(data);
        })
        .catch(({ err }) => {
          context.commit(SET_ERROR, err);
          reject(err);
        });
    });
  },
  [SUBMIT_APOSTILLE_APPOINTMENT](context, data){
    return new Promise((resolve, reject) => {
      var data_ = data;
      ApiService.post("f/submit-apostille-service/", data_)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ err }) => {
        reject(err);
      });
    })
  },
  [SUBMIT_APOSTILLE_PAYMENT](context, data){
    return new Promise((resolve, reject) => {
      var data_ = data;
      ApiService.post("f/create-apostille-payment/", data_)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response.data);
      });
    })
  },
  [FETCH_F_AVAILABLE_COUNTRY](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("f/get-available-countries/", data)
        .then(({ data }) => {
          resolve(data.data);
          context.commit(SET_DOCUMENT_COUNTRY_LIST, data.data);
        })
        .catch(({ err }) => {
          reject(err);
          context.commit(SET_ERROR, err);
        });
    });
  },
  [FETCH_F_COUNTRY_SHIPPING_RATE](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("f/get-selected-country-shipping-rate/", data)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ err }) => {
          reject(err);
          context.commit(SET_ERROR, err);
        });
    });
  },
  [SEND_APOSTILLE_EMAIL](context, data){
    return new Promise((resolve, reject) => {
      ApiService.post("resend-apostille-email-to-client/", data)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ err }) => {
          reject(err);
          context.commit(SET_ERROR, err);
        });
    });
  }
};
const mutations = {
  [GET_SERVICE_PROVIDER_DATA](state, data) {
    state.serviceProviderData = data;
  },
  [SET_TIME_ZONE](state, value) {
    state.timezone = value;
  },
  [SET_SELECTED_TIME](state, VALUE) {
    state.selectedTime = VALUE;
  },
  [SET_CITIZEN_TITLE](state, VALUE) {
    state.selectedCitizenTitle = VALUE;
  },
  [SET_SERVICE_TYPE](state, value) {
    state.service_type = value;
  },
  [UPDATE_PAYMENT_TYPE](state, value) {
    state.paymentType = value;
  },
  [SET_ERROR](state, data) {
    state.errors = data;
  },
  [SET_ERROR_STATUS](state, data) {
    state.error_status = data;
  },

  [SET_CITIES](state, data) {
    state.cities = data;
  },
  [SET_APIKEYS](state, data) {
    state.logsapikeys = data;
  },
  [SET_SERVICE_ORI_LIST](state, data) {
    state.oriList = data;
  },
  [SET_STATES](state, data) {
    state.states = data;
  },
  [SET_COUNTRIES](state, data) {
    state.countries = data;
  },
  [SET_RACES](state, data) {
    state.races = data;
  },
  [SET_EYECOLORS](state, data) {
    state.eyeColors = data;
  },
  [SET_HAIRCOLORS](state, data) {
    state.hairColors = data;
  },
  [SET_PlACE_OF_BIRTH](state, data) {
    state.placeOfBirth = data;
  },

  //applicant contact information
  [UPDATE_CITY](state, value) {
    state.applicantContact.city = value;
  },
  [UPDATE_STATE](state, value) {
    state.applicantContact.state = value;
  },
  [UPDATE_FIRSTNAME](state, value) {
    state.applicantContact.first_name = value;
  },
  [UPDATE_MIDDLENAME](state, value) {
    state.applicantContact.middle_name = value;
  },
  [UPDATE_LASTNAME](state, value) {
    state.applicantContact.last_name = value;
  },
  [UPDATE_EMAIL](state, value) {
    state.applicantContact.email = value;
  },
  [UPDATE_CONFIRMEMAIL](state, value) {
    state.applicantContact.confirm_email = value;
  },
  [UPDATE_PHONENO](state, value) {
    state.applicantContact.phone_no = value;
  },
  [UPDATE_ADDRESSLINE1](state, value) {
    state.applicantContact.address_line_1 = value;
  },
  [UPDATE_ADDRESSLINE2](state, value) {
    state.applicantContact.address_line_2 = value;
  },
  [UPDATE_ZIPCODE](state, value) {
    state.applicantContact.zip_code = value;
  },
  [UPDATE_REASONFORFINGERPRINT](state, value) {
    state.applicantContact.reason = value;
  },
  //applicant personal information
  [UPDATE_SOCIAL_SECURITY](state, value) {
    state.applicantPersonal.social_security_no = value;
  },
  [UPDATE_DATEOFBIRTH](state, value) {
    state.applicantPersonal.date_of_birth = value;
  },
  [UPDATE_PLACE_OF_BIRTH](state, value) {
    state.applicantPersonal.place_of_birth = value;
  },
  [UPDATE_DATEOFBIRTH_MONTH](state, value) {
    state.applicantPersonal.dob_m = value;
  },
  [UPDATE_DATEOFBIRTH_DAY](state, value) {
    state.applicantPersonal.dob_d = value;
  },
  [UPDATE_DATEOFBIRTH_YEAR](state, value) {
    state.applicantPersonal.dob_y = value;
  },
  [UPDATE_COUNTRY](state, value) {
    state.applicantPersonal.country_of_citizenship = value;
  },
  [UPDATE_GENDER](state, value) {
    state.applicantPersonal.gender = value;
  },
  [UPDATE_RACE](state, value) {
    state.applicantPersonal.race = value;
  },
  [UPDATE_EYECOLOR](state, value) {
    state.applicantPersonal.eye_color = value;
  },
  [UPDATE_HAIRCOLOR](state, value) {
    state.applicantPersonal.hair_color = value;
  },
  [UPDATE_HEIGHT](state, value) {
    state.applicantPersonal.height = value;
  },
  [UPDATE_WEIGHT](state, value) {
    state.applicantPersonal.weight = value;
  },

  [SET_CLIENTS](state, data) {
    state.clients = data;
  },
  [SET_CLIENTS_SP](state, data) {
    state.sp_clients = data;
  },
  [SET_SERVICES](state, data) {
    state.services = data;
  },
  [SET_ARCHIVALS](state, data) {
    state.archivals = data;
  },
  [SET_SERVICE_CODES](state, data) {
    state.services_codes = data;
  },
  [SET_SMS_STATUS](state, value) {
    state.smsStatus = value;
  },
  [UPDATE_ORI](state, value) {
    state.service_ori = value;
  },

  //payment details

  [SET_PAYMENT_CARD_NUMBER](state, value) {
    state.payment_details.card_number = value;
  },
  [SET_PAYMENT_CARD_NUMBER_PHONE](state, value) {
    state.payment_details.phone = value;
  },
  [SET_PAYMENT_CARD_NUMBER_PHONE_CODE](state, value) {
    state.payment_details.phone_code = value;
  },
  [SET_PAYMENT_CARD_NUMBER_POSTAL](state, value) {
    state.payment_details.postal = value;
  },
  [SET_PAYMENT_CARD_HOLDER_NAME](state, value) {
    state.payment_details.card_holder_name = value;
  },
  [SET_PAYMENT_CARD_ADDRESS](state, value) {
    state.payment_details.card_address = value;
  },
  [CARD_STATE](state, value) {
    state.payment_details.card_state = value;
  },
  [CARD_COUNTRY](state, value) {
    state.payment_details.card_country = value;
  },
  [SET_PAYMENT_CARD_CITY](state, value) {
    state.payment_details.card_city = value;
  },
  [SET_PAYMENT_CARD_EXPIRY_DATE](state, value) {
    state.payment_details.card_expiry = value;
  },
  [SET_PAYMENT_CARD_CVV_NUMBER](state, value) {
    state.payment_details.card_cvv_no = value;
  },
  [SET_PAYMENT_CARD_TYPE](state, value) {
    state.payment_details.card_type = value;
  },
  [SET_PAYMENT_CARD_PROPERTY](state, data) {
    state.paymentCardProperty = data;
  },
  [SET_COUNTRY_PHONE_CODE](state, item) {
    state.selectedCountryPhoneCode = item;
  },
  [APPLICANTDETAIL](state, data) {
    state.applicant_detail = data;
  },
  [RECAPTCHATOKEN](state, data) {
    state.recaptcha_token = data;
  },

  //Apostille Service
  [SET_DOC_TRANSLATION_RATE_STATUS](state, value) {
    state.doc_translation_rate_status = value;
  },
  [SET_DOC_SCAN_RATE_STATUS](state, value) {
    state.doc_scan_rate_status = value;
  },
  [SET_DOCUMENT_LIST](state, value){
    state.info_documents = value;
  },
  [SET_DOCUMENT_COUNTRY](state, value){
    state.document_country_id = value;
  },
  [SET_DOCUMENT_COUNTRY_LIST](state, value){
    state.document_countries_list = value;
  },

  //Apostille Service
  [SET_DELIVERY_ADDRESS_NAME](state, value) {
    state.delivery_address.name = value;
  },
  [SET_DELIVERY_ADDRESS_COMPANY_NAME](state, value) {
    state.delivery_address.company_name = value;
  },
  [SET_DELIVERY_ADDRESS_ADDRESS](state, value){
    state.delivery_address.address = value;
  },
  [SET_DELIVERY_ADDRESS_CITY](state, value){
    state.delivery_address.city = value;
  },
  [SET_DELIVERY_ADDRESS_POSTAL_CODE](state, value){
    state.delivery_address.postal_code = value;
  },
  [SET_DELIVERY_ADDRESS_STATE](state, value){
    state.delivery_address.state = value;
  },
  [SET_DELIVERY_ADDRESS_COUNTRY](state, value){
    state.delivery_address.country = value;
  },
  [SET_DELIVERY_ADDRESS_CONTACT](state, value){
    state.delivery_address.contact = value;
  },
  [SET_DELIVERY_ADDRESS_PHONE_CODE](state, value) {
    state.delivery_address.phone_code = value;
  },
  [SET_IS_SELECTED_EXPEDITED_RATE](state, value){
    state.is_expedited_rate = value;
  },
  [SET_APOSTILLE_SUBMITTED_DATA](state, value){
    state.apostille_submitted_data = value;
  },
  [SET_IS_SIGNATURE_REQUIRED](state, value){
    state.is_signature_required = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};
